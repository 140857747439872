.person_achivments_plashka {
    width: 100%;
    min-height: 70px;;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;
    
    flex-wrap: wrap;

    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 12px;   

    column-gap: 1px;

    overflow-y: auto;
    height: fit-content;
}