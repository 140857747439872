.plateAvatar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 12px;

}

.plateAvatar_left {  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.plateAvatar_right {
    width: 100%;
    height: 100%;
    overflow: hidden; 
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.plateAvatar_right_text {
    color: black;
    font-size: 90%;
    font-family: 'PFD';
    text-align: left;
    text-transform: uppercase;
}