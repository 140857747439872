.card-ptichka-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: none;

    color: white;
}

.card-ptichka-cover img {
    width: 100%;
    height: 100%;
}

.card-ptichka-content {

    width: 100%;
    height: 100%;
    
}

.card-ptichka-content-logo-img {

    position: absolute;
    width: 60%;

    top: 20%;
    left: 20%;   


}

.card-ptichka-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-ptichka-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-ptichka-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-ptichka-content-title {

    position: absolute;
    width: 80%;
    text-align: center;

    top: 5%;   
    left: 10%;

    font-size: 0.5vw;

    font-family: 'Arial';

    filter: drop-shadow(3px 3px black);

    font-weight: bold;

    color: white;

}

.card-ptichka-content-tier {
    position: absolute;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    top: 6%;

    font-size: 120%;

    font-family: 'Bewlay';
    text-transform: uppercase;  

    font-weight: bold;

}