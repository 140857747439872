.medal_shalve_plashka {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(rgb(235, 235, 235), white); */

    /* background-position: center; */

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    /* border: 1px solid gray; */

    background: linear-gradient(rgb(0, 13, 29), rgb(5, 56, 80));
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    border-radius: 10px;

    overflow-y: auto;

}

.medal_shalve_plashka_ascount {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    overflow-y: hidden;
}

.medal_shalve_plashka_medal_ascount > span {
    font-size: 15px !important;
}