
.menu-main {
  width: 100%;
  height: 56px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  position: relative; 
}

.main_center_container_mobile .menu-main {
    background: linear-gradient(white, rgb(226, 226, 226));
    border-bottom: 1px solid rgb(212, 212, 212);
}

.menu-main-left-thing {
    width: 20px;
    min-width: 20px;
    height: 32px;
}

.menu-logo {
    width: 170px;
    min-width: 170px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menu_mini_logo {
    margin-left: 50px;
}

.menu-logo img {
    width: 131px;
    height: auto;
}

.main_center_container_mobile .menu-main {
    height: 46px;
    column-gap: 10px;
}

.main_center_container_mobile .menu-logo,
.main_center_container_mobile .menu_mini_logo
{
    width: 36px;
    min-width: 35px;
}

.main_center_container_mobile .menu-logo img,
.main_center_container_mobile .menu_mini_logo img 
{
    width: 36px;
    height: auto;
}

.menu-right-part {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 100%;

    position: relative;

}

.menu-right-part-back {
    position: absolute;
    left: 0px;
    top: 12px;

    width: 100%;
    height: 32px;

    z-index: 0;
}

.menu-right-part-upper {
    position: absolute;
    left: 0px;
    top: 0px;

    width: 100%;
    height: 56px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    z-index: 1;
}

.menu-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 40px;
    min-width: 600px;
}

.menulink {
    color: white;
    font-family: 'PFD';
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 12px;
    font-size: 15px;
    font-weight: 300;
}

.menulink a {
    color: white !important;
}

.menu-links_mini {

    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}

.menu-links_mini .menulink {
    width: 100%;
    height: 42px;
    margin-top: 10px;
    border-bottom: 1px solid rgb(196, 194, 194);
}

.menu-links_mini .menulink a {
    color: rgb(5, 56, 80) !important;
}

.driver_menu_content {

    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 10px;
}

.driver_menu_content .menu_search_conatiner {
    margin-left: 0px;
    margin-bottom: 15px;
}