.person_big_plashka_mob {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    background: linear-gradient(rgb(235, 235, 235), white);
    /* background: rgb(255, 255, 255); */
    display: flex;
    flex-direction: row;
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    padding-top: 20px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;

    position: relative;
    overflow: hidden;
}

.person_big_plashka_mob_rank_gold {
    background: linear-gradient(rgb(255, 153, 1), rgb(58, 0, 44));
    filter: drop-shadow( 0px 0px 8px rgba(255, 225, 89, 0.767) );
}

.person_big_plashka_mob_rank_silver {
    background: linear-gradient(rgb(194, 193, 193), rgb(82, 82, 82));
    filter: drop-shadow( 0px 0px 8px rgb(255, 255, 255) );
    border: 2px solid white;
}

.person_big_plashka_mob_rank_bronze {
    background: linear-gradient(rgb(230, 159, 119), rgb(216, 93, 35));
    filter: drop-shadow( 0px 0px 8px rgb(245, 177, 132) );
    border: 2px solid rgb(241, 145, 107);
}

.bgbgbg {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1 !important;
}

.pbp_mobile_right_left { 
    margin-left: 20px;
    margin-right: 20px;
}

.pbp_mobile_right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    margin-right:10px;
}

.pbp_mobile_name {
    font-size: 20px;
    font-family: 'MyriadPro';
    padding-right:20px;
    line-height: 20px;
}

.pbp_mobile_social {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; 
    column-gap: 10px;
    padding-right:20px;
}

.pbp_mobile_info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: auto;
}

