.global_footer {
    width: 100vw;
    height: fit-content;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    /* font-family: 'Uni'; */
    color: rgb(204, 204, 204) !important;

    background: rgb(0, 27, 36) !important;

    
    font-family: 'PFD';
    /* text-transform: uppercase; */
    /* font-weight: bold; */
    position: relative;

}

.global_footer_middle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;  

    width: 1200px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.main_center_container_mobile 
.global_footer_middle {
    width: 100vw;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;  
    padding-top: 10px;
    padding-bottom: 10px;

    padding-left: 10px;
    padding-right: 10px;


}

.global_footer_lefter {
    min-width: 350px;
}

.global_footer_righter {
    text-align: right;
    width: 100%;
}

.main_center_container_mobile
.global_footer_righter {
    margin-top: 30px;
    text-align: left;
}

.global_footer_p1 {
    font-size: 12px;
    height: 10px;
}

.global_footer_p2 {
    font-size: 14px;
    height: 11px;
}

.global_footer_p3 > span {
    margin-right: 22px;
    color: gray !important;
    font-size: 10px;
    /* white-space: nowrap; */
}

.global_footer_p3 > a {
    margin-right: 22px;
    color: gray !important;
    font-size: 10px;
    /* white-space: nowrap; */
}

.global_footer_p1_first {
    display: flex;
    flex-direction: row;
    column-gap: 3px;
}

.global_footer_p1_first .pumpcin_hw {
    transform: translateY(-2px);
    opacity: 0.7;
}