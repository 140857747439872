.shadule-comp-container {
    width: 100%;
    /* height: fit-content; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.shadule-comp-main {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.shadule-comp-left-container {
    width: 100%;
    height: fit-content;

    padding-top: 10px;
    padding-bottom: 10px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    row-gap: 5px;

}

.shadule-comp-left-container-header {
    width: 270px;
    height: fit-content;

    background: white;

    margin-left: 20px;
    margin-bottom: 10px;

    border-radius: 4px;
    overflow: hidden;

    cursor: pointer;
}


.shadule-comp-right-container {
    position: relative;
    display: column;
}

.shadule-comp-right-container-header {
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-left: 40px;
    position: relative;
}

.shadule-comp-right-container-shadule {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.shadule-comp-right-container-header-day {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    border-left: 1px solid rgb(0, 27, 44);
    border-right: 1px solid rgb(0, 27, 44);

    background: rgb(3, 67, 97);

}

.shadule-comp-right-container-header-day p {
    width: fit-content;
    height: 10px;
    font-family: 'Uni';
    color: white;

    font-size: 20px;

}

.shadule-comp-right-container-hours {
  position: relative;
  width: 40px;
  min-width: 40px;
  height: 100%;
}

.shadule-comp-right-container-hours-hour {

    position: absolute;
    left: 0px;

    color: rgb(3, 67, 97);
    font-family: 'MyriadPro';
    font-size: 15px;
    transform: translateY(-50%);

}



.shadule-comp-right-container-table {
    position: relative;
    width: 100%;
    height: 100%;
}

.shadule-comp-right-container-table-row {
    position: absolute;
    left: 0px;
    top: 0px;
    border: 1px solid rgba(128, 128, 128, 0.486);
}

.shadule-comp-right-container-table-column {
    position: absolute;
    left: 0px;
    top: 0px;
    border: 1px solid rgba(128, 128, 128, 0.486);
}

.shadule-comp-right-container-table-supgroup {
    position: absolute;
    left: 0px;
    top: 0px;  

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 1px solid rgb(218, 217, 217);
    border-radius: 3px;

    text-align: center;
    line-height: 5px;

    row-gap: 8px;

}

.shadule-comp-right-container-table-supgroup p {

    /* color: black;  
    font-family: 'MyriadPro';
    height: 5px;
    width: fit-content;
    font-size: 18px; */

    color: black;
    font-family: 'MyriadPro';
    /* height: 6px; */
    /* width: -webkit-fit-content; */
    width: fit-content;
    font-size: 18px;
    line-height: 14px;
    /* margin-bottom: 0px; */
    height: 14px;
    margin: 0px;
}

.shadule-comp-right-container-table-supgroup
.___center-tire {
    height: 9px;
    line-height: 5px;
}

.shadule-comp-right-container-table-supgroup
.___title_block {
    width: 100%;
    white-space: nowrap;
    overflow-y: visible;
    overflow-x: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
}

.shadule-comp-right-container-table-supgroup
.___title_block p {
    max-width: 90%;
    white-space: nowrap;
    overflow-y: visible !important;
    overflow-x: hidden;
    height: 20px;
    padding-top: 3px;
    text-overflow: ellipsis;
}


.main_center_container_mobile
.shadule-comp-right-container-table-supgroup
.___title_block p {
    font-size: 10px;
}

.shadule-comp-right-container-table-supgroup
.___time_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}