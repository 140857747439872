.results_mini_component {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    /* background: linear-gradient( rgba(255, 255, 255, 0.651), rgba(255, 255, 255, 0.534)); */

    border-radius: 4px;
    background-position: center;
    border-bottom: 1px solid rgb(197, 194, 179);
    /* background-image: image(silver); */
    /* background-image: url('/public/images/ver2/textures/gold.jpg'); */
}

.results_mini_component_event {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
    margin-left: 5px;
    cursor: pointer;
}

.results_mini_component_right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;   
    width: 100%;
}

.results_mini_component_right_eventtitle {
    font-family: 'MyriadPro';
    font-size: 16px;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.results_mini_component_right_downed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;  
    width: 100%;
    padding-left: 10px;
    padding-right: 12px;
}

.results_mini_component_right_downed > p {
    font-family: 'PFD';
    font-size: 16px;
    height: fit-content;
    color: rgb(5, 56, 80);
    margin: 0px;
    padding: 0px;
}

.group_big_plate_medals_inside {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 

    column-gap: 5px;
}
