.person_raiting_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    background: white;
    display: flex;
    flex-direction: column;
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.281));
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 12px;
    /* padding-left: 15px;
    padding-right: 15px; */
    row-gap: 20px;
}

.person_raiting_plashka 
.numberText {
    width: 100px !important;
}

.person_raiting_plashka_oneseason {
    display: flex;
    flex-direction: column;
}

.person_raiting_plashka_season {
    font-size: 16px;
    color: rgb(5, 56, 80);
    font-family: 'PFD';
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 15px;
}

.person_raiting_plashka_raiting {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 5px;
}


.person_raiting_plashka_raiting_one_row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center; 
    width: 100%;

    height: 56px;
    background: linear-gradient( 90deg, rgb(230, 230, 230), white)
}

.main_center_container_mobile
.person_raiting_plashka_raiting_one_row {
    height: 40px;
}

.person_raiting_plashka_raiting_one_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; 
    width: 220px;
}

.main_center_container_mobile
.person_raiting_plashka_raiting_one_info {
    width: 80px;
}


.person_raiting_plashka_raiting_one_devider {
    width: 2px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.person_raiting_plashka_raiting_one_devider_inside {
    width: 1px;
    height: 70%;
    background: rgb(5, 56, 80);
    transform: skew(-20deg);
}