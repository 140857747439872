.trefa_svg_icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.trefa_svg_icon svg {
    width: 100%;
    height: 100%;
}

.trefa_svg_icon img {
    width: 100%;
    height: 100%;
}


.trefa_svg_icon_star_follow .starfill { fill: rgb(34, 117, 185) !important }
.trefa_svg_icon_star_follow .starstroke { stroke: rgb(0, 65, 117) !important }


.trefa_svg_icon_star_participation .starfill { fill: rgb(231, 208, 0) !important }
.trefa_svg_icon_star_participation .starstroke { stroke: rgb(207, 77, 1) !important }

.ui_icon__nofil { fill: none }
.ui_icon__fill { fill: rgb(0, 65, 117) }

.trefa_svg_icon__check
.ui_icon__fill { fill: rgb(255, 255, 255) }

/* RANKS */

.trefa_svg_icon_star_place1 .starfill { fill: rgb(231, 208, 0) !important }
.trefa_svg_icon_star_place1 .starstroke { stroke: rgb(247, 220, 102) !important }
.trefa_svg_icon_star_place1 svg { filter: drop-shadow(0px 2px 2px rgb(231, 208, 0)) !important }


.trefa_svg_icon_star_place2 .starfill { fill: rgb(214, 214, 211) !important }
.trefa_svg_icon_star_place2 .starstroke { stroke: rgb(161, 161, 161) !important }
.trefa_svg_icon_star_place2 svg { filter: drop-shadow(0px 2px 2px rgb(255, 255, 255)) !important }

.trefa_svg_icon_star_place3 .starfill { fill: rgb(214, 153, 72) !important }
.trefa_svg_icon_star_place3 .starstroke { stroke: rgb(160, 90, 33) !important }
.trefa_svg_icon_star_place3 svg { filter: drop-shadow(0px 2px 2px rgb(214, 153, 72)) !important }

.trefa_svg_icon_star_place5 .starfill { fill: rgb(134, 194, 230) !important; }
.trefa_svg_icon_star_place5 .starstroke { stroke: rgb(114, 183, 230) !important }