.video_little_plashka {
    width: 100%;
    height: 100%;
    position: relative;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    /* background: rgb(243, 243, 243); */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */
    user-select: none;
    background: rgb(5, 56, 80);
    /* border-radius: 5px; */
}

/* .video_little_plashka .ui_button_link {
    visibility: hidden;
}

.video_little_plashka .ui_button_link {
    visibility: hidden;
} */

.video_little_plashka-discription {
    font-family: 'MyriadPro';
    font-size: 16px;
    color: white; 

    display: flex;
    flex-direction: column;
}

.video_little_plashka-discription
.person_little_category_name
{
    font-size: 16px;
}

.video_little_plashka-discription
.person_little_category_name a
{
    color: white !important;
    font-weight: 100 !important;
    font-family: 'MyriadPro';
}

.video_little_plashka-discription
.person_little {
    background: none;
}

 
.ytp-title-text {
    visibility: hidden !important;
}