.modal_categorys_content {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}

.infoModal_mobile
.modal_categorys_content
{
    column-gap: 5px;
}
.showInfoModal
.ant-modal-content {
    width: 650px !important;
    min-width: 650px !important;
}
.showPDFRulesModal
.ant-modal-content {
    width: 1050px !important;
    min-width: 750px !important;
}

.infoModal_mobile 
.ant-modal-content {
    width: 320px !important;
    min-width: 320px !important;
}

.infoModal_mobile
.ant-modal-body {
    padding: 10px !important;
}


.modal_categorys_left {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.infoModal_mobile {
    width: 60%;
}

.modal_categorys_left img {
    width: 90%;
    height: auto;
}

.modal_categorys_right {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'PFD';
}

.modal_categorys_title {
    font-size: 23px;
    color: rgb(5, 56, 80);
    font-weight: 800;
}

.infoModal_mobile
.modal_categorys_title
{
    font-size: 14px;
    color: rgb(5, 56, 80);
    font-weight: 800;
}

.modal_categorys_content {
    font-size: 16px;
    font-weight: 600;
}


.infoModal_mobile
.modal_categorys_content {
    font-size: 13px;
    font-weight: 600;
}

.infoModal_mobile
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-right: 0px;
    margin-left: 0px;
}