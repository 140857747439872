.loader__container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.laoder__text {
    width: fit-content;
    height: 20px;
    text-align: center;
    color: gray;
    font-size: 14px;
    font-family: 'PFD';
}

.loader__img {
    height: 100%;    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.loader__img img{
    height: 100%;
    max-height: 100px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    animation-name: 'loader_animation';
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.485, 0.135, 0.15, 0.86);

}

@keyframes loader_animation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg); 
    }
}