.calendar_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    /* background: rgb(243, 243, 243); */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */

    user-select: none;
    /* border-radius: 5px; */
    /* border: 1px solid rgb(197, 197, 197); */
}

.calendar_header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
}

.calendar_header_item {
    width: 30%;
    text-align: center;
    font-family: 'MyriadPro';
    font-size: 16px;
    cursor: pointer;
    color: rgb(37, 37, 37);
    transition-duration: 0.5s;
}

.calendar_header_item:hover {
    font-size: 17px;
}

.calendar_header_item___selected {
    font-weight: bold;
    font-size: 17px;
    color: rgb(5, 56, 80);
}


.calendar_plashka_outerheader {
    width: 100%;
    height: 40px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-family: 'PFD';
    font-size: 26px;
    color: rgb(5, 56, 80);

    column-gap: 5px;
}





.calendar_days_header {    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
}

.calendar_days_header_item {
    width: 13%;
    min-width: 13%;
    text-align: center;
    font-family: 'MyriadPro';
    font-size: 16px;
    color: rgb(0, 56, 94); 
}

.calendar_body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    width: 100%;
    height: fit-content;

    row-gap: 2px;
    column-gap: 2px
}

/* Это у нас собственно плашечка */
.calendar_body_oneday {
    width: 13%;
    min-width: 13%;
    text-align: center;
    /* border: 1px solid rgb(212, 212, 212); */
    height: 27px;
    font-family: 'Uni';

    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

.calendar_body_oneday > span {
    padding-right: 7px;
    padding-bottom: 3px;
    font-size: 12px;
}

.calendar_mini_events {
    position: absolute;
    width: fit-content;
    height: fit-content;
    /* width: 80px; */

    /* background: rgb(223, 223, 223); */
    /* border-radius: 3px; */

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    top: -12px;
    left: -7px;

    overflow: visible;
}

.calendar_body_oneday__blank {
    /* border: 1px solid rgb(233, 233, 233); */
    border: none;
}

.calendar_body_oneday__today {
    color: rgb(5, 56, 80);
    border: 2px solid #EF7F1A;
    /* background: #EF7F1A; */
    border-radius: 10px;

    
    border: 2px solid #003038;
}

.calendar_body_oneday__past {
    color: rgb(187, 187, 187);
}

.calendar_body_oneday__event {
    background: rgb(185, 227, 255); 
    /* color: white; */
    border-radius: 10px;
    color: rgb(5, 56, 80);
}
.calendar_body_oneday__event_championship {
    /* background: #dffcb8;  */
}

/* .calendar_body_oneday__event span {
    background: rgb(0, 56, 94); 
    color: white;
    border-radius: 30px;
} */

.ant-popover-inner-content {
    padding: 0px;
    margin: 0px;
    width: fit-content;
    height: fit-content;
}

.calendar-popover-content {
    min-width: 250px;
}