.solo-minisequencte-div {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: white;

}

.solo-minisequencte-div-card {
  max-width: 40px;
  max-height: 40px;
  margin-left: 4px;
}

.solo-minisequencte-div-card img {
  width: 100%;
  height: auto;
}

.solo-minisequencte-div-seq-name {
  color: black;
  font-size: 15px;
  max-width: 400px;
}

.solo-minisequence-shuffle {
  border: 1px solid rgb(255, 102, 0);
  border-radius: 10px;
}

.mini_sequence_only_tech {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 3px;
  width: fit-content;
}

.mini_sequence_only_tech_container {
  height: 90%;
  width: fit-content;
  background: #053850;
  border-radius: 7px;
}

.mini_sequence_only_tech_container img {
  height: 100%;
  width: auto;
}