.application_little {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgb(196, 196, 196);
    /* background: rgb(0, 28, 51); */
    /* background: linear-gradient(rgb(0, 29, 46), rgb(1, 43, 68)) */
    background: rgb(255, 255, 255);

    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    margin-left: 0px;

    /* image-rendering: pixelated; */
    /* image-resolution: 3200; */
    image-rendering: optimizeSpeed;

    column-gap: 30px;

}

.application_little:last-child {
    border-bottom: none;
}

.application_little_loading {
    background: none;
    opacity: 0.5;
    margin-left: 20px;
}

.application_little__avatar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.application_little__cordao {
    position: absolute;
    top: 50%;
    left: 45%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

}

.application_little__fio {
    width: 100%;
    height: fit-content;
    font-family: 'MyriadPro';
    margin-bottom: 15px;
    /* color: white; */
}

.application_little__suffix {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
}

.application_little__school {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
