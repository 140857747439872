
.main_center_container_mobile 
.seqpage_mainpage_main_plashka_inner
.ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
    padding-left: 3px !important;
    padding-right: 3px !important;
    font-size: 10px !important;
}

.main_center_container_mobile 
.subgroup_page_main_discription {
    padding-left: 10px;
    padding-right: 10px;
}