.group_big_plate {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
    background: linear-gradient(rgb(240, 240, 240), rgb(226, 226, 226));
    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */

    border: 1px solid rgb(214, 214, 214);

    border-top-left-radius: 35px; 
    border-bottom-right-radius: 20px; 

    padding-left: 15px;
    column-gap: 10px;

    transition-duration: 0.6s;
}

.group_big_plate:hover {
    background: white;
    cursor: pointer;
}

.group_big_plate_name {
    width: 50%;
    font-size: 19px;
    font-family: 'PFD';
    text-transform: uppercase;
    color: rgb(5, 56, 80);
}

.main_center_container_mobile .group_big_plate_name {
    font-size: 17px;
    line-height: 16px;
}

.group_big_plate_medals_inside {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 

    column-gap: 5px;
}