.event_usual_plashka {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(rgb(235, 235, 235), white); */

    /* background-position: center; */
    background-size: cover !important;
    background-position: center !important;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    overflow: hidden;

    position: absolute;
}

.event_usual_plashka_fon {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left:0px;
    /* z-index: 1; */
    background-size: cover !important;
    background-position: center !important;
}

.event_usual_plashka_fon_usual {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left:0px;
    /* z-index: 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0, 68, 100);
    font-family: 'PFD';
    font-size: 20px;
    color: white;
}

.event_usual_plashka_fon_usual > span {
    width: 70%;
    line-height: 18px;
}

.event_usual_plashka__date {
    width: 60px;
    height: 30px;
    background: rgba(5, 56, 80, 0.781);
    border: 1px solid rgb(5, 56, 80);
    border-radius: 5px;
    color: white;
    position: absolute;
    top: 10px;
    left: 10px;
    /* z-index: 2; */
}

.event_usual_plashka__date_text {
    width: fit-content;
    height: fit-content;
    font-family: 'MyriadPro';
    line-height: 15px;
    /* z-index: 1; */
}

.event_usual_plashka__status {
    width: 100%;
    height: fit-content;
    border-radius: 5px;
    color: white;
    position: absolute;
    top: 10px;
    left: 0px;   
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    justify-content: flex-end;
    padding-right: 10px;
    /* z-index: 1; */
}

.event_usual_plashka__count {

    position: absolute;
    top: 45px;
    left: 10px;   

    height: 23px;
    width: fit-content;
    
    background: rgba(5, 56, 80, 0.884);
    border: 1px solid rgb(5, 56, 80);
    border-radius: 5px;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding-left: 5px;
    padding-right: 5px;

    column-gap: 3px;

    color: white;
    /* z-index: 1; */
}

.main_center_container_mobile
.event_usual_plashka__status {
    padding-right: 0px;
    top: 5px;
}

.event_usual_plashka__downed_title {
    position: absolute;
    width: 100%;
    height: max(20%, 35px);
    left: 0px;
    top: calc(100%);
    background: rgba(5, 56, 80, 0.925);

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    transition-duration: 1s;
    opacity: 0;
    line-height: 15px;
    /* z-index: 1; */

}


.event_usual_plashka:hover
.event_usual_plashka__downed_title {
    animation: event_title_animation;
    animation-duration: 0.5s;
    animation-fill-mode: forwards; 
    transition-duration: 1s;
    opacity: 1;
}

.event_usual_plashka__downed_title_text {
    color: white;
    font-family: 'MyriadPro';
    margin-left: 20px;
    height: fit-content;
}


.main_center_container_mobile 
.event_usual_plashka__downed_title_text {
    margin-left: 14px;
    font-size: 14px !important;

}

@keyframes event_title_animation {
    from {
        top: calc(110%);
    }
    to {
        top: calc(100% - max(20%, 35px));
    }
}

@keyframes event_title_animation_ {
    from {
        top: calc(110%);
    }
    to {
        top: calc(100% - max(20%, 35px));
    }
}