.slider_some_plashka {
    width: 100%;
    height: 100%;
    background: none;

    /* background-position: center; */

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    overflow: hidden;

}

.slider_some_plashka_one {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.slider_some_plashka_one_top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.slider_some_plashka_one_top
img {
    width: 95%;
    height: auto;
}

.main_center_container_mobile 
.slider_some_plashka_one_top
img {
    width: 70%;
    height: auto;
}

.slider_some_plashka_one_bottom {
    height: 100%;
    padding: 5%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    font-size: 15px;
    font-family: 'MyriadPro';
    text-align: center;
    line-height: 14px;
}

.main_center_container_mobile 
.slider_some_plashka_right_text {
    font-family: 'MyriadPro';
    line-height: 14px;
    font-size: 16px;
}
