.statistics_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    /* background: rgb(243, 243, 243); */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */
    user-select: none;
    /* border-radius: 5px; */
    padding-left: 5px;
    padding-right: 5px;
}

.statistics_plashka_next {
    /* width: 65px; */
    font-size: 9px;
    line-height: 10px;
    margin-left: auto;
}