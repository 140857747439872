.money_component {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
    background: none;
}

.money_component img {
    width: 60%;
    height: auto;
    background: none !important;
}

.money_component .money_component_amount {
    font-family: 'Uni';
    font-size: 14px;
}