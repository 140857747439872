.trefa_text_container {
    /* min-width: 300px; */
    width: 100%;
    height: fit-content;
    font-family: 'PFD';
    font-size: 21px;
    font-weight: 300;
    color: rgb(83, 83, 83);
}

.trefa_text_container h1 {
    margin-bottom: 0px;
    font-family: 'DIN';
    color: #053850 !important;
    font-size: 32px !important;
    height: fit-content !important;
    line-height: 35px !important;
}

.trefa_text_container h2 {
    margin-bottom: 0px;
    font-family: 'DIN';
    color: #053850 !important;
    font-size: 22px !important;
    height: fit-content !important;
    line-height: 25px !important;
}

.main_center_container_mobile .trefa_text_container {
    font-size: 16px;
}

.trefa_text_container_textblock {
    margin-bottom: 20px;
    word-wrap: break-word;
}

.trefa_text_container_imgblock {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.trefa_text_container_imgblock img {
    /* max-width: 70vw !important; */
}

.person_includes_text {
    color: rgb(5, 56, 80);
    min-width: 60px;
}

.person_includes_text:hover {
    color: rgb(1, 112, 163);
}

.include_video {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.technics_includes img {
    background: rgb(0, 29, 61);
    border-radius: 3px;
}