.element-card {
 
    width: 100px;
    height: 120px;

    font-size: 10px;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start; 
    
    border: 1px solid gray;
    border-radius: 0.3vw;

    position: relative;
    
    animation: shadow-card 0.5s infinite ease-in-out;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;

    /* transform: translateY(-30.0px) perspective(1000px) rotateX(44deg); */
    transform:  perspective(500px) rotateX(44deg);

    opacity: 0;
    
    background: rgb(0, 16, 36);

}

.element-card-usual {
  border: 2px solid white;
  background: rgb(0, 45, 63);
}

.element-card-usual_lvl1 {
  background: linear-gradient(rgb(116, 75, 0), rgb(36, 0, 0));
}
.element-card-usual_lvl2 {
  background: linear-gradient(rgb(151, 151, 151), rgb(54, 54, 54));
}

.element-card-usual_lvl3 {
  background: linear-gradient(rgb(170, 130, 0), rgb(85, 69, 0));
}



@keyframes shadow-card {
    from {
      color: rgb(219, 212, 146);
      transform:  perspective(500px) rotateX(85deg);
      opacity: 0.9;
    }
    to {
      color: white;
      transform:  perspective(500px) rotateX(0deg);
      /* transform:  perspective(0px) rotateX(0deg); */
      opacity: 1.0;
    }
  }




.element-card-title {

    width: 100%;
    text-align: center;

}

.element-card-upper {

    position: absolute;

    z-index: 10;

    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;
    opacity: 0.0;
    background: rgba(6, 31, 53, 0.87);
    
    transition-duration: 0.5s;
    
    border-radius: 0.3vw;
    
    visibility: hidden;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  
  }
  
  .element-card:hover .element-card-upper {
  
    visibility: visible;
    opacity: 1;
    transition-duration: 0.5s;
  
  }

  .element-card-poopover {
      width: 300px;
  }

  .element-card-poopover-mobile {
    width: 100px;
}

  .element-card-upper-icon {
      font-size: 2.5vw !important;
      margin: 0.4vw !important;
      color: gray !important;
      cursor: pointer;
      transition-duration: 0.2s;
  }

  .element-card-upper-icon:hover {
    font-size: 2.8vw !important;
    margin: 0.35vw !important;
    color: white !important;
    transition-duration: 0.2s;
}

  .element-card-upper-icon-disabled {
    font-size: 2.5vw !important;
    margin: 0.4vw !important;
    color: gray !important;
    cursor: not-allowed;
  }

  .element-mobile-icon {
    font-size: 5.5vw !important;
  }

  .element-card-bottom_line {
    position: absolute;

    z-index: 1;

    top: 86%;
    left: 0px;

    width: 100%;
    height: 10%;

    padding-left: 10%;
    padding-right: 10%;

    overflow: hidden;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background: linear-gradient(rgb(1, 25, 53), rgb(0, 31, 66));

    font-family: 'MyriadPro';
}

.element-card-bottom_line_otkat {
  position: absolute;

  z-index: 1;

  top: 30%;
  left: 0px;

  width: 100%;
  height: 20%;

  overflow: hidden;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 170%;

  color: red;

  background: rgba(0, 0, 0, 0.521);

  font-family: 'MyriadPro';
}

.element-card-bottom_line-changes {
  position: absolute;

  z-index: 1;

  top: 66%;
  left: 0px;

  width: 100%;
  height: 30%;

  overflow: hidden;

  display: flex;
  flex-direction: row;
  justify-content: center;

  background: linear-gradient(rgb(1, 25, 53), rgb(0, 31, 66));

  font-family: 'MyriadPro';

  padding-top: 10px !important;

  text-align: center;

  font-size: 150%;

}

/* Тут у нас картинка сама и т.д. */
.element-card-content {

  position: absolute;

  z-index: 2;

  top: 0px;
  left: 0px;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: flex-start; 

  overflow: hidden;

}

/* Тут у нас картинка сама и т.д. */
.element-card-content {

  position: absolute;

  z-index: 3;

  top: 0px;
  left: 0px;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

}

.element-card-content img {
 
  width: 90%;
  height: auto;
  
}

.element-card-conten-changes-img {
 
  width: 60% !important;
  height: auto;
  
}

/* А это обложка задняя, тут костамизация*/
.element-card-cover {
 
  position: absolute;

  z-index: 1;

  top: 0px;
  left: 0px;

  width: 100%;
  height: 100%;


  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: flex-start; 

  background: linear-gradient(rgb(0, 58, 0), rgb(128, 108, 0));
  
}

.element-card-title_new {
  text-align: center;
}

.element-card-title_new_needed {
  font-weight: bold;
  color: orangered;
}

.element-card-content_mine {
  background: linear-gradient(rgb(0, 52, 112), rgb(6, 34, 66));
}