.image_uploader_container_header {
    color: rgb(5, 56, 80);
    font-size: 18px;
    margin-bottom: 10px;
    color: rgb(90, 90, 90); 
}

.image_uploader_container_title {
    font-size: 17px;
    color: rgb(5, 56, 80);

    margin-top: 10px;
    margin-bottom: 3px;

}

.image_uploader_container_text {
    color: rgb(90, 90, 90);
    font-size: 12px;
}

.image_uploader_changer {
    margin-bottom: 15px;
}