.owl_quest_plashka {
    width: 100%;
    height: 100%;
    background: none;

    /* background-position: center; */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    overflow: hidden;

}

.owl_quest_plashka img {
    width: 100%;
    height: auto;
}
