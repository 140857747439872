.quest_full {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: rgb(255, 255, 255);

    padding: 7px 7px;
    row-gap: 8px;

    font-family: 'PFD';
    font-size: 18px;

}

.quest_full_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: rgb(0, 57, 83);
    font-family: 'UNI';

    column-gap: 20px;
}

.quest_full_header img {
    width: 100px;
    height: auto;
}

.quest_full h1 {
    margin-top: 20px;
    height: 15px;
    font-size: 24px;
    color: rgb(0, 57, 83);
    font-family: 'UNI';
}

.quest_full_tasks {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.quest_full_tasks img {
    width: 25px;
    height: auto;
}

.quest_full_footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.quest_full_footer_reward {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}