.person_video_big_plashka {
    width: 100%;
    height: fit-content;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    background: none;
    display: flex;
    flex-direction: row;

    justify-content: space-around;

    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;

    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 12px;
}