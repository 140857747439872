.data_changer_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    padding: 30px;
    padding-top: 10px;
    font-family: 'PFD';

    column-gap: 30px;

}

.data_changer_container_mobile {
    padding: 0px !important;
    padding-top: 5px !important;
}

.data_changer_header {
    width: 100%;
    height: fit-content;
    color: rgb(90, 90, 90);
    font-size: 18px;
    margin-bottom: 30px;
}

.data_changer_devider {
    width: 100%;
    height: 20px;
}

.data_changer_devider_withtext {
    width: 90%;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 35px;
    margin-bottom: 25px;
}

.data_changer_devider_withtext_line_first {
    height: 1px;
    width: 5%;
    min-width: 5%;
    background: rgb(5, 56, 80);
}

.data_changer_devider_withtext_text {
    width: fit-content;
    font-family: 'PFD';
    font-size: 14px;
    text-transform: uppercase;
    white-space: nowrap;
    color: rgb(5, 56, 80);
    padding-left: 10px;
    padding-right: 10px;
}

.data_changer_devider_withtext_line_second {
    height: 1px;
    width: 100%;
    background: rgb(5, 56, 80);
}

.data_changer_component {
    width: 250px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.data_changer_component_title {
    font-size: 17px;
    color: rgb(5, 56, 80);
}

.data_changer_component_title_noneed {
    color: rgb(110, 134, 145);
}

.data_changer_component_comp {
    color: rgb(48, 48, 48);
}

.data_changer_component_text {
    color: rgb(90, 90, 90);
    font-size: 12px;
}

.data_changer_footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}


.data_changer_component_title_participators {
    margin-top: 15px;
}

.data_changer_aps_main {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid rgb(0, 57, 83);
    border-radius: 5px;
}

.data_changer_aps_main::before {
    font-size: 10px;
    content: 'Добавить вариант'
}

.data_changer_aps_main_options {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}

.data_changer_aps_left {
    display: flex;
    flex-direction: column; 
    width: 250px;
}

.data_changer_aps_right {
    display: flex;
    flex-direction: column;    
    width: 250px;
    row-gap: 5px;
}

.data_changer_aps_left::before {
    content: 'Название';
}

.data_changer_aps_oneselect {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
}

.data_changer_aps_oneselect_adding {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
}

.data_changer_aps_oneselect_adding::before {
    content: 'Добавить вариант'
}

.data_changer_aps_main .data_changer_footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.data_changer_payment {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.data_changer_payment_oneblock {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid gray;
    padding-bottom: 15px;
}

.data_changer_payment_oneblock_options {
    display: flex;
    flex-direction: column;
}

.data_changer_payment_oneblock_options_oneoption {
    height: 37px;
}


.data_changer_payment_oneblock_left {
    display: flex;
    flex-direction: row;
    min-width: 300px;
    padding-top: 43px;
}

.data_changer_payment_oneblock_title {
    min-width: 150px;
    display: flex;
    flex-direction: column;
    min-height: 50px;
    row-gap: 3px;
}

.data_changer_payment_oneblock_title>span {
    font-size: 20px;
}

.data_changer_payment_oneblock_checksplit {
    display: flex;
    flex-direction: row;
    column-gap: 5px;  
    transform: scale(0.9);
}

.data_changer_payment_oneblock_right {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}

.data_changer_payment_onepayment_block {
    display: flex;
    flex-direction: column;
}

.data_changer_payment_onepayment_date {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
    height: 40px;
    width: 150px;
}

.data_changer_payment_onepayment_inputs {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.data_changer_payment_oneblock_blank {
    border: 1px solid gray;
    border-radius: 15px;
    color: gray;
    cursor: pointer;
    padding: 20px;
    text-align: center;
    background: rgb(247, 247, 247);

    transition-duration: 1s;

    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.data_changer_payment_oneblock_blank:hover {
    
    border: 1px solid rgb(71, 71, 71);
    background: rgb(255, 255, 255);
    color: rgb(71, 71, 71);
}

.data_changer_payment_oneblock_blank>span {
    width: fit-content;
    height: fit-content;
}



/* СКИДКИ !!!!!!!!!! */

.data_changer_payment_sales_title {

    margin-top: 20px;

    font-family: 'PFD';
    text-transform: uppercase;
    font-size: 17px;
    color: rgb(5, 56, 80);
}

.data_changer_payment_sales {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.data_changer_payment_sales__blank {
    border: 1px solid gray;
    border-radius: 5px;
    color: gray;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    background: rgb(247, 247, 247);

    transition-duration: 1s;

    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.data_changer_payment_sales__blank:hover {
    
    border: 1px solid rgb(71, 71, 71);
    background: rgb(255, 255, 255);
    color: rgb(71, 71, 71);
}

.data_changer_payment_sales__blank>span {
    width: fit-content;
    height: fit-content;
}

.data_changer_payment_sales__one {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
}


.data_changer_payment_sales__one_right {
    width: 150px;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    column-gap: 5px;
}

.data_changer_payment_sales__one_right > span {
    min-width: 90px;
    text-align: right;
}


/* ADMISSION */

.data_change_admission_add_typical_title {
    font-family: 'PFD';
    text-transform: uppercase;
    font-size: 14px;
    color: rgb(5, 56, 80);
    margin-top: 20px;
}

.data_change_admission_add_typical_components {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.data_change_admission_adding_component {
    width: fit-content;
    height: 30px;
    padding: 10px;
    padding-top: 6px;

    background: rgb(159, 175, 175);
    font-size: 12px;
    color: rgb(2, 30, 43);

    border-radius: 5px;
    cursor: pointer !important;
}


.dataChanger_scoresComponent {
    display: flex;
    flex-direction: row;
    width: 100%;
    column-gap: 10px;
}

.dataChanger_scoresComponent_one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}