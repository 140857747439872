.modal_notification_body {
    width: 100%;
    height: 100%;
}

.modal_notification_button {
    width: 100%;
    height: 50px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}