.registration-div-all {
  height: fit-content;
  font-family: 'MyriadPro';
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.fromModal {
  position: relative !important;
  height: 160px;
  width: 100%;
  left: 0px !important;;
  top: 0px !important;;
}

.registration-div-inside {
  width: 230px;
}

.registration-div-inside form {
  width: 230px;
  font-size: 18px;
}

.registration-div-inside input {
  width: 230px;
  font-size: 18px;
}

.registration_space {
  height: 10px;
}


.registration-div-forgot {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  width: 230px;
  justify-content: space-between;
}

.registration-div-forgot a {
  Color: white;
  margin-right: 15px;
}

.registration-div-forgot Button {
  Color: black;
  width: 100px;
}

.registration-div-forgot Button:hover {
  Color: white !important;
  background: rgb(0, 45, 73)  !important;
}

.registration-div-inside-row {
  display: flex;
  flex-direction: row;
}

.registration-div-inside-row:first-child {
  cursor: pointer;
}

.social-networks-div {

}

@media screen and (min-width:480px) and (max-width:999px) {

   .registration-div-all {
    left: 0px;
    right: initial;
    bottom: 0px;
    top: 0px;
   }

   .registration-div-inside {
     visibility: hidden;
   }

   .registration-div svg {
    visibility: hidden;    
   }

}

.registrationA {
  cursor: pointer;
}

