.mountin_container {
    position: relative;
    overflow: visible;
}

.mountin_container svg {
    overflow: visible;
}

.pologonGraph_text1 {
    transform: translateX(15px) translateY(-50%);
}
.pologonGraph_text2 {
    transform: translateX(15px) translateY(-50%);
}
.pologonGraph_text3 {
    transform: translateX( calc(-100% - 15px) ) translateY(-50%);
}

.pologonGraphQuad_text1{
    transform: translateX(0%) translateY(-120%);
}
.pologonGraphQuad_text2{
    transform: translateX( calc(-100% - 0px) ) translateY(-122%);
}
.pologonGraphQuad_text3{
    transform: translateX( calc(-115%) ) translateY(0%);
}
.pologonGraphQuad_text4{
    transform: translateX( 15% ) translateY(0%);
}


.mountin_container_polygon {
    stroke: rgb(27, 187, 187);
    stroke-width: 2px;
    stroke-opacity: 0.6;
}

.mountin_container circle {
    fill: white;
    /* stroke: rgb(0, 39, 54); */
    stroke-width: 2px;
    position: relative;
}

.pologonGraph_content {
    position: absolute;
    width: 200px;
    height: 150px;
    background: red;
}

.mountin_container
line {
    stroke: rgba(87, 102, 102, 0.644);
    stroke-width: 1px;
    stroke-dasharray: 300;
}

.mountin_container
svg
polygon {
    stroke: rgba(87, 102, 102, 0.253);
    stroke-width: 1px;
    stroke-opacity: 0.6;
    fill: rgba(199, 158, 82, 0.178);
}

/* .pologonGraph {
    stroke: rgba(2, 221, 221, 0.87);
    stroke-width: 2px;
    stroke-opacity: 0.9;
    fill: none;
} */

.pologonGraph_line_polygon {
    fill: rgba(6, 45, 97, 0.651) !important;
    /* fill: rgba(6, 46, 97, 0.418); */
}


.pologonGraph_text {
    position: absolute;
    font-family: 'UNI';
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.26);
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 6px;
}

.pologonGraph_text 
svg {
    fill: rgb(0, 35, 58);
    stroke: none;
}
.pologonGraph_text 
svg polygon {
    fill: rgb(0, 35, 58);
    stroke: none;
}

.pologonGraph_text 
.pologonGraph_text_right
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.pologonGraph_text_right
.pologonGraph_text_right_top
{
    font-size: 14px;
    height: 40%;
}

.pologonGraph_text_right
.pologonGraph_text_right_bottom
{
    font-size: 9px;
    height: fit-content
}

.mountain_strike
svg {
    fill: red !important;
}

.mountain_flow
svg {
    fill: rgb(21, 35, 114) !important;
}

.mountain_jump
svg {
    fill: rgb(0, 162, 255) !important;
}

.mountain_freeze
svg 
polygon
{
    fill: rgb(87, 35, 16) !important;
}