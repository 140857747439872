.numberText {
    width: 40px;
    height: 60px;

    display: flex;
    flex-direction: column;
}

.numberText_upper {
    width: 100%;
    height: 70%;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.numberText_lower {
    width: 100%;
    height: 30%; 
    max-height: 30%;  
    overflow: hidden; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.numberText_upper_text {
    color: rgb(0, 17, 36);
    font-size: 90%;
    font-family: 'Uni';
    text-align: center;
}

.numberText_lower_text {
    color: rgb(53, 53, 53);
    font-size: 90%;
    font-family: 'Uni';
    text-align: center;
}