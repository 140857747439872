.solo-minisequencerow-div {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  max-width: 800px;
  height: 60px;

  width: fit-content;

  border: 1px solid rgb(0, 56, 82);
  transition-duration: 0.2s;

  position: relative;

}

.solo-minisequencerow-div-noscale {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  max-width: 800px;
  height: 60px;

  width: fit-content;

  border: 1px solid rgb(0, 56, 82);
  transition-duration: 0.2s;

  position: relative;
}

.solo-minisequencerow-div-isSelected {
  background: rgb(187, 187, 187);
}

.solo-minisequencerow-div:hover {

  transition-duration: 0.5s;
  transform: scale(1.05);

}

.solo-minisequencerow-div-person{

  width: 240px;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-right: 10px;

}

.solo-minisequencerow-div-person-noname {
  width: 80px;
}

.solo-minisequencerow-div-person .person_little{
  background: none;
}

.solo-minisequencerow-div-sequence {
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: #053850;
  padding: 8px;
  border-radius: 8px;

}

.solo-minisequencerow-div-status {
  width: 70px;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-left: 10px;

  font-family: 'MyriadPro';

}

.solo-minisequencerow-div-status-confirm {

  font-size: 30px !important;
  padding-top: 5px;
  font-family: 'DIN';
  font-size: 30px;
  color:#053850;

}

.solo-minisequencerow-div-status-waiting {

  font-size: 12px;

}


.solo-minisequencerow-div-dashed {
  
  border: 1px solid rgb(36, 38, 39);

}

.solo-minisequencerow-div-status-waiting-dashed {
  color: rgb(131, 130, 130);
}

.solo-minisequencerow-div-video {

  position: absolute;
  top: 0px;
  left: calc(100% - 20px);

}

.solo_icon_checked_tutorial {

  fill: rgb(240, 92, 24);
  stroke-width: 4;
  width: 25px;
  height: 25px;
}

.solo-minisequencerow-div-withvenok {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.solo-minisequencerow-div-withvenok_venok svg {
  width: 19px;
  height: 25px;
}


.solo-minisequencerow-div-withvenok span {
  font-family: 'DIN';
  font-size: 30px;
  color: #ffffff;
  height: 20px;
  line-height: 20px;
  text-transform: uppercase;
}

.solo-minisequencerow-div-withvenok_3 span {
  background: linear-gradient(45deg, #fd7200 33%, #e6bb00 66%, #ffc400);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0B2349;
  display: table;
}

.solo-minisequencerow-div-withvenok_2 span {
  background: linear-gradient(45deg, #374c52 33%, #337575);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0B2349;
  display: table;
}

.solo-minisequencerow-div-withvenok_1 span {
  background: linear-gradient(45deg, #865326 33%, #b98d39 66%, #ac7a0f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0B2349;
  display: table;
}


.solo-minisequencerow-div-withvenok_3 .solo-minisequencerow-div-withvenok_venok svg {
  fill: url(#Gradient1);
}

.solo-minisequencerow-div-withvenok_2 .solo-minisequencerow-div-withvenok_venok svg {
  fill: url(#Gradient2);
}

.solo-minisequencerow-div-withvenok_1 .solo-minisequencerow-div-withvenok_venok svg {
  fill: url(#Gradient3);
}

.solo-minisequencerow-div-withvenok_venok-right {
  transform: scaleX(-1);
}