.cordao_subject_table {
    width: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;

    overflow-y: auto;
    height: fit-content !important;

    /* background: rgb(243, 243, 243);
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */
    /* border-radius: 15px; */
}

.main_center_container_mobile .cordao_subject_table {
    column-gap: 5px;
    row-gap: 5px;
    justify-content: flex-start;

}

.cordao_subject_table-separator {
    height: 0px;
    margin-bottom: 10px;
    width: 100%;
    color: rgb(5, 56, 80);
    font-family: 'MyriadPro';
    font-size: 20px;
}

.main_center_container_mobile .cordao_subject_table-separator {
    height: 20px;
    margin-bottom: 3px;
    font-size: 17px;
}

.cordao_subject_table-switch-item {
    cursor: pointer;
    color: gray;
    margin-left: 10px;
}

.cordao_subject_table-switch-item__selected {
    color: rgb(5, 56, 80);
}

.cordao_subject_table__oneraiting {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cordao_subject_table__oneraiting__place_container {
    border: 2px solid rgb(5, 56, 80);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Uni';
    background: linear-gradient(rgb(0, 13, 29), rgb(5, 56, 80));
    color: white;
}

.cordao_subject_table__oneraiting__place_container_upper {
    height: 45%;
}

.cordao_subject_table__oneraiting__place_container_middle,
.cordao_subject_table__oneraiting__place_container_down
{
    height: 20%;
}
