.person_wall_container {
    border: 1px solid rgb(197, 197, 197);
    
    /*DEFAULT*/
    background: linear-gradient(#d4d4d4, #b4b4b4);

    background-size: cover !important;
    border-radius: 20px;
    position: relative;
}

.person_wall_cell {
    position: absolute;
    border: 1px solid rgba(156, 156, 156, 0.603);
    border-radius: 5%;
    z-index: 10;
}

.person_wall_cell_inventar {
    position: absolute;
    border: 1px solid rgba(156, 156, 156, 0.603);
    border-radius: 5%;
    z-index: 10;
}

.person_wall_cell_inventar:hover {
    background: rgba(0, 128, 0, 0.575);
}


.person_wall_cell_draggable {
    position: absolute;
    /* border: 1px solid rgba(83, 182, 2, 0.603); */
    border: none;
    border-radius: 5%;
    cursor: pointer;

    background-image: url('./images/hand.png');
    background-size: cover;

    /* z-index: 1100; */
}

.person_wall_cell_todrop {
    width: 100%;
    height: 100%;
}

.person_wall_cell_candrop {
    width: 100%;
    height: 100%;
    background: rgba(0, 128, 0, 0.575);
}

.person_wall_item {
    position: absolute;
}

.person_wall_cell_draggable {
    visibility: hidden;
}

.person_wall_item:hover .person_wall_cell_draggable {
    visibility: visible;
}

.person_wall_item img {
    width: 100%;
    height: 100%;
}

.person_wall_downbutton {
    border-radius: 50%;
    background: radial-gradient(white, rgba(255, 255, 255, 0) 70%);
    position: absolute;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    font-family: 'UNI';
    padding-top: 2%;

    transition-duration: 0.6s;
    opacity: 0.3;
    color: black;

    cursor: pointer;
}

.person_wall_downbutton:hover {
    color: white;
    opacity: 1;
}

.person_wall_downbutton_godrop {
    background: radial-gradient(rgb(240, 72, 72), rgba(87, 0, 0, 0) 70%);
    color: white;
    opacity: 1;
}

.person_wall_upbutton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition-duration: 0.6s;
    opacity: 0.8;
    color: rgb(185, 185, 185);

    position: absolute;
    z-index: 900;

    font-family: 'PFD';
    letter-spacing: 3px;
    font-size: 20px;

    cursor: pointer;
    background: rgb(0, 44, 94);
    ;
}

.person_wall_upbutton:hover {
    color: rgb(255, 255, 255);
    opacity: 1;
}


.person_wall_choose_mode {
    background: rgba(255, 255, 255, 0.616);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    position: absolute;
    z-index: 999;

    align-content: flex-start;
    overflow-y: auto;

}

/* .person_wall_choose_mode_dragged {
    z-index: 0;
    background: none;
} */

.person_wall_choose_mode_cross {
    cursor: pointer;
    position: absolute;
    left: calc(100% - 20px);
    transition-duration: 0.5s;
    z-index: 1200;
}

.person_wall_choose_mode_cross:hover {
    transform: scale(1.3);
}