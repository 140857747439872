.achivment_tooltip_container {
    width: 230px;
    height: fit-content;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding: 6px;
    row-gap: 3px;
    background: linear-gradient(white, rgb(219, 219, 219));
    border-radius: 10px;
}

.achivment_tooltip_container img {
    width: 210px;
    height: 210px;
}


.achivment_tooltip_container h2 {
    width: 100%;
    text-align: center;
    font-family: 'DIN';
    font-size: 30px;
    color: rgb(0, 36, 56);
    padding: none !important;
    margin: none !important;
    margin-bottom: 0px !important;
    line-height: 30px;
}

.achivment_tooltip_container_text {
    font-family: 'PFD';
    font-size: 16px;
    text-align: center;
    width: 90%;
    color: rgb(0, 36, 56);
}

.achivment_tooltip_container_param {
    width: 70%;
    text-align: left;
    font-family: 'DIN';
    font-size: 15px;
    color: rgb(0, 36, 56);
}

/* LITTLE */

.little_achivments_plashka_achiv {
    /* width: 110px;
    height: 110px; */
    image-rendering: optimizeQuality;
    position: relative;
}

.little_achivments_plashka_achiv > img {
    width: 100%;
    height: 100%;
}

.little_achivments_plashka_achiv_bar {
    position: absolute;
    /* left: 20px;
    top: 110px;
    width: 70px;
    height: 6px; */

    border: 1px solid rgb(189, 200, 202);
    background: linear-gradient( rgb(201, 208, 211), rgb(235, 238, 238) );
    border-radius: 2px;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.little_achivments_plashka_achiv_bar_inner {
    height: 100%;
    width: 0px;
    background: linear-gradient(goldenrod, rgb(255, 174, 0))
}

/* HELPER */

.condition_text_container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.condition_text_container_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 12px;
}

.condition_text_container_items {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
}