.quest_little {
    width: 100%;
    height: 50px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 7px;
    /* background: rgb(0, 28, 51); */
    /* background: linear-gradient(rgb(0, 29, 46), rgb(1, 43, 68)) */
    background: rgb(255, 255, 255);

    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    margin-left: 0px;

    /* image-rendering: pixelated; */
    /* image-resolution: 3200; */
    image-rendering: optimizeSpeed;

    padding-left: 7px;
    column-gap: 8px;

    font-family: 'PFD';
    text-transform: uppercase;
    font-size: 18px;
    cursor: pointer;
    line-height: 19px;

}

.quest_little:hover {
    background: rgb(229, 232, 233);
}

.quest_little img {
    height: 80%;
    width: auto;
}

.quest_little_progress {
    margin-left: auto;
}

.quest_little_allDone {
    animation: doneShine 2s;
    animation-iteration-count: infinite;
}

@keyframes doneShine {
    from {
        background: rgb(255, 255, 255);
    }
    50% {
        background: green;
    }
    to {
        background: rgb(255, 255, 255);
    }
}

.quest_little_dashed {
    color: gray;
}