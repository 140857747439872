.fight_modal_container__categoryName {
    font-family: 'UNI';
    text-align: center;
    font-size: 14px;
    margin-bottom: 4px;
}


.fight_modal_container_mobile 
.fight_modal_container__categoryName {
    font-size: 9px;
    margin-bottom: 7px;
    margin-top: 3px;
}

.fight_modal_container__competition {
    margin-top: 12px;
    padding: 10px;
    border: 1px solid rgba(128, 128, 128, 0.301);
    border-radius: 4px;
}

.fight_modal_container__personRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.fight_modal_container__personRow_person {
    width: 200px;
    max-width: 200px;
}


.fight_modal_container_mobile 
.fight_modal_container__personRow_person {
    width: 170px;
    max-width: 170px;
}


.fight_modal_container__personRow_cell {
    width: 70px;
    max-width: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'UNI';
    font-size: 17px;
}

.fight_modal_container_mobile 
.fight_modal_container__personRow_cell {
    width: 50px;
    max-width: 50px;
    font-size: 12px;
}

.fight_modal_container__personRow_cell span {
    font-family: 'PFD';
    font-size: 13px;
    font-weight: bold;
}


.fight_modal_container_mobile .fight_modal_container__personRow_cell span {
    font-size: 9px;
} 

.fight_modal_container__personRow_cell_win {
    color: rgb(0, 77, 0);
}

.fight_modal_container__personRow_loose {
    color: rgb(131, 26, 0);
}

.fight_modal_container__personRow_cell_video {
    width: 50px;
    max-width: 50px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid gray;
    border-radius: 4px;
    cursor: pointer;
    transition-duration: 0.6s;
}


.fight_modal_container_mobile 
.fight_modal_container__personRow_cell_video {
    width: 30px;
    max-width: 30px;  
    height: 20px;
}


.fight_modal_container__personRow_cell_video:hover {
    background: rgb(153, 175, 185);
}