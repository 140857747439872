.ui_chooser_plashka {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(rgb(235, 235, 235), white); */

    /* background-position: center; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    /* border: 1px solid gray; */

    overflow-y: auto;

}

.ui_chooser_plashka_mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    overflow-y: hidden;
}

.ui_chooser_plashka_raitingmobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
    column-gap: 5px;
    height: 35px;
    max-height: 35px;
}

.ui_chooser_plashka a {
    width: 100%;
}

.ui_chooser_plashka_item {
    font-size: 17px;
    font-family: 'MyriadPro';
    color: rgb(70, 70, 70);
    font-weight: 100;
    /* background: white;
    border-bottom: 1px solid rgb(228, 228, 228); */
    width: 100%;
    min-width: 100%;
    padding: 3px;
    padding-left: 6px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    column-gap: 5px;

}

.ui_chooser_plashka_item_mobile {
    padding: 0px !important;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.ui_chooser_plashka_item_mobile_selected {
    color: white;
}

.ui_chooser_plashka_item_mobile_up {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 50px;
    height: 50px;

    border-radius: 10px;
    /* background:rgb(221, 221, 221);  */
    background: linear-gradient(rgb(235, 235, 235), rgb(221, 221, 221));

    transition-duration: 0.5s;
}

.ui_chooser_plashka_item_mobile_selected
.ui_chooser_plashka_item_mobile_up {
    background:rgb(1, 87, 128); 
    background: linear-gradient(rgb(1, 87, 128), rgb(0, 53, 78));
}

.ui_chooser_plashka_item_mobile_down {
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    height: 20px;
    font-family: 'MyriadPro';
    vertical-align: middle;
    color: rgb(70, 70, 70);
}

.ui_chooser_plashka_raitingmobile
.ui_chooser_plashka_item_mobile_down {
    font-size: 12px;
    line-height: 12px;
    font-family: 'MyriadPro';
    width: 40px;
}

.ui_chooser_plashka_raitingmobile
.ui_chooser_plashka_item_mobile_selected{
    font-weight: bold;
}


.ui_chooser_plashka_item:hover {
    background:rgb(231, 231, 231); 
}

.ui_chooser_plashka_item_selected {
    /* color: rgb(5, 56, 80); */
    color: white;
    background:rgb(1, 87, 128); 
}

.ui_chooser_plashka_item_selected:hover {
    background:rgb(1, 87, 128); 
}