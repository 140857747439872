.ui_button_link {

    width: 110px;
    height: 40px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: rgb(0, 27, 53);
    border-radius: 15px;

    opacity: 0.5;
    cursor: pointer;
    transition-duration: 1s;

    z-index: 3;

    column-gap: 10px;

}

.ui_button_link_horizontal {
    width: 65px;
    height: 50px;
    background: rgb(0, 27, 53);
    border-radius: 7px;
    cursor: pointer;
    z-index: 3;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 6px;
}
.ui_button_link_horizontal span {
    font-family: 'MyriadPro';
    font-size: 12px;
    color: white;
    line-height: 14px;
}
.ui_button_link_horizontal svg {
    width: 23px;
    height: 23px;
}
.ui_button_link_horizontal .fil0 {
    fill: rgb(255, 255, 255) !important;
}



.ui_button_link span {
    font-family: 'MyriadPro';
    font-size: 12px;
    color: white;
    line-height: 14px;
}

.ui_button_link:hover {
    opacity: 0.8;
}

.ui_button_link svg {
    width: 30px;
    height: 30px;
}

.ui_button_link .fil0 {
    fill: rgb(128, 197, 224) !important;
}

.ui_button_link_mini {
    width: 55px;
    height: 20px;
}

.ui_button_link_mini span {
    font-size: 7px;
    line-height: 8px;
}

.ui_button_link_mini svg {
    width: 10px;
    height: 10px;
}

.ui_button_link_mini_long {
    width: 75px;
    height: 24px;
}

.ui_button_link_mini_long span {
    font-size: 12px;
    line-height: 12px;
    padding-top: 1px;
}

.ui_button_link_mini_long svg {
    width: 12px;
    height: 12px;
}

/* LINK BUTTON */

.link_button_light {
    border: 1px solid rgb(58, 116, 96);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgb(204, 226, 223);
    cursor: pointer;
    transition-duration: 0.7s;
}
.link_button_light:hover {
    background: rgb(152, 184, 179);
}

.link_button_light span {
    font-family: 'MyriadPro';
    font-size: 18px;
    color: rgb(34, 73, 60);
}