.sh_subgroup-container {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    border: 1px solid rgb(219, 219, 219);
    font-family: 'MyriadPro';
    color: rgb(0, 56, 80);
    background: rgb(231, 230, 224);
}

.sh_subgroup-container-marker {
    height: 100%;
    width: 18px;
    border: none;
}

.sh_subgroup-container-left {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    
    padding: 4px;
    padding-left: 8px;
    padding-right: 10px;
}

.sh_subgroup-container-left 
.___big-text {
    font-weight: bold;
    font-size: 14px;
    font-family: 'Uni';
    height: 22px;
}


.sh_subgroup-container-left 
.___little-text {
    font-size: 16px;
    height: 18px;
}

.sh_subgroup-container-right {
    width: 68px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.sh_subgroup-container-right >div{
    width: 50px;
    height: 50px;
    border: 3px solid red;
    background: linear-gradient( red, brown );
    border-radius: 7px;
    color: white;
    text-align: center;
    line-height: 18px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.shadule-comp-left-container
.___header {
    width: 100%;
    height: 60px;
    background: rgb(189, 198, 199);
    padding: 14px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.shadule-comp-left-container
.___header
.____adres {
    font-size: 22px;
    font-family: 'PFD';
    font-weight: 100;
    line-height: 1px;
    color: black;
}

.shadule-comp-left-container
.___header
.____metro {
    font-size: 22px;
    font-family: 'MyriadPro';
    font-weight: 100;
    line-height: 1px;
    color: black;
}


.main_center_container_mobile
.___header {
    height: 56px;
    padding: 6px;
}


.main_center_container_mobile
.shadule-comp-left-container
.___header
.____adres {
    font-size: 14px;
    line-height: 14px;
}

.main_center_container_mobile
.shadule-comp-left-container
.___header
.____metro {
    font-size: 14px;
    line-height: 14px;
}

.shadule-comp-left-container
.__subgroups
{
    width: 100%;
}



.sh_subgroup_bycity_main {
    width: 100%;
    min-height: 500px;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 16px;

}

.main_center_container_mobile
.sh_subgroup_bycity_main { 
    max-width: 100vw;
    overflow-x: auto;
}

.sh_subgroup_bycity_citys {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.sh_subgroup_bycity_city {
    font-family: 'UNI';
    font-size: 25px;
    color: #053850;
}

.sh_subgroup_bycity_subgroups {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.sh_subgroup_bycity_subgroup {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid rgb(199, 199, 199);
    padding-left: 20px;

}

.sh_subgroup_bycity_subgroup_group {
    width: 150px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}
.sh_subgroup_bycity_subgroup_group_upper {
    width: 56px;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sh_subgroup_bycity_subgroup_group_down {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'PFD';
    font-size: 16px;
    color: #053850;
    line-height: 18px;

}

.sh_subgroup_bycity_subgroup_title {
    width: 450px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; 
}

.main_center_container_mobile
.sh_subgroup_bycity_subgroup_title {
    max-width: 160px;
}

.sh_subgroup_bycity_subgroup_title_upper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    row-gap: 1px;
    line-height: 15px;
}

.sh_subgroup_bycity_subgroup_title_upper_left {
    width: fit-content;
    font-family: 'PFD';
    font-weight: bold;
    font-size: 17px;
    color: #053850;
}

.sh_subgroup_bycity_subgroup_title_upper_right {
    font-family: 'PFD';
    font-weight: bold;
    font-size: 15px;
    color: #053850;
    padding-top: 2px;
}

.sh_subgroup_bycity_subgroup_title_down {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start; 
    column-gap: 15px; 
}

.sh_subgroup_bycity_subgroup_title_down_metro {
    font-weight: bold;
}

.sh_subgroup_bycity_subgroup_shadule {
    width: 220px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.sh_subgroup_bycity_subgroup_shadule_one {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    column-gap: 10px;

    font-family: 'PFD';
    font-weight: bold;
    font-size: 12px;
    color: #053850;
}

.main_center_container_mobile
.sh_subgroup_bycity_subgroup_shadule_one {
    font-size: 8px;
}

.sh_subgroup_bycity_subgroup_payments {
    width: 170px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.sh_subgroup_bycity_subgroup_payment_one {
    width: 100%;
    font-family: 'PFD';
    font-weight: bold;
    font-size: 12px;
    color: #053850;
}

.sh_subgroup_bycity_subgroup_request {
    width: 100px;
    height: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: red;
    color: white;
    font-family: 'MyriadPro';
    cursor: pointer;
}

.main_center_container_mobile
.sh_subgroup_bycity_subgroup_request {
    width: 50px;
    height: 30px;
    font-size: 9px;
    margin-left: 20px;
}

.sh_subgroup_bycity_subgroup_request span {
}