.place_rect {
    height: 100%;
    border-radius: 20%;
    text-align: center;
    font-family: 'Uni';

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.place_rect_as_medal {
    border-radius: 50%;
}

.place_rect_as_medal_blank {
    opacity: 0;
}

.place_rect_place_1 {
    border: 2px solid gold;
    background: linear-gradient(rgb(255, 136, 0), gold);
    color: rgb(255, 255, 255)
}

.place_rect_place_2 {
    border: 2px solid rgb(194, 194, 194);
    background: linear-gradient(rgb(255, 255, 255), rgb(205, 214, 216));
    color: rgb(92, 92, 92)
}

.place_rect_place_3 {
    border: 2px solid #cd7f32;
    background: linear-gradient(rgb(248, 150, 37), #cd7f32);
    color: rgb(255, 255, 255)
}

.place_rect_blank {
    border: 2px solid #9aacb4;
    background: linear-gradient(rgb(94, 120, 124), #4c6166);
    color: rgb(255, 255, 255)  
}