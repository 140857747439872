.person_data_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 12px;
}

.person_data_plasha_oneplashka {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 14px;
    margin-left: 10px;
}

.person_data_plasha_oneplashka_kubok {
    margin-top: 7px;
    margin-bottom: 7px;

    padding-left: 20px;
}

.person_data_plasha_oneplashka_clicked {
    cursor: pointer;
}

.person_data_plasha_oneplashka_left {
    width: 60px;
    height: 60px;    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.person_data_plasha_oneplashka_left img {
    width: 50px;
    height: 50px;
}

.person_data_plasha_oneplashka_right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 12px;
}

.person_data_plasha_oneplashka_right_upper {
    width: 100%;
    height: 16px;
    font-size: 14px;
    font-family: 'PFD';
    text-transform: uppercase;
    font-weight: bold;
    color: rgb(5, 56, 80);
}

.person_data_plasha_oneplashka_right_downed {
    width: 100%;
    height: 20px;
    font-size: 21px;
    font-family: 'MyriadPro';
}

.person_data_plasha_devider {
    width: 100%;
    height: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
}

.person_data_plasha_devider_line {
    width: 100%;
    height: 1px;
    background: rgba(5, 56, 80, 0.438);
}

.person_data_plasha_devider_text {
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    height: 16px;

    font-size: 11px;
    font-family: 'PFD';
    text-transform: uppercase;
    font-weight: bold;
    color: rgb(5, 56, 80);
}

.person_data_status_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 8px;
}

.person_data_scoresstatus {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
}

.person_data_scoresstatus img {
    width: 100%;
    height: auto;
}

.person_data_scoresstatus .ant-progress-text {
    font-family: 'Uni';
    font-size: 14px;
}

.person_data_moneystatus {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
}

.person_data_moneystatus img {
    width: 60%;
    height: auto;
}

.person_data_moneystatus .person_data_moneystatus_amount {
    font-family: 'Uni';
    font-size: 14px;
}


.person_data_kubok_1 {
    position: relative;
    width: 100px;
    height: 100px;

    margin-right: 20px;
}

.person_data_kubok_2 {
    position: relative;
    width: 100px;
    height: 100px;
    margin-right: 20px;
}

.person_data_kubok_3 {
    position: relative;
    width: 100px;
    height: 100px;
    margin-right: 20px;
}

.person_data_kubok_img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    
}

.person_data_kubok_1 img{
    width: 70px;
    height: 70px;    
    filter: drop-shadow( 0px 0px 2px rgb(255, 196, 0) );
    border-radius: 20%;
    /* border: 0px solid rgb(197, 197, 197); */
}

.person_data_kubok_2 img{
    width: 65px;
    height: 65px;    
    filter: drop-shadow( 0px 0px 2px rgb(190, 190, 190) );
    border-radius: 20%;
    /* border: 0px solid rgb(197, 197, 197); */
}

.person_data_kubok_3 img{
    width: 60px;
    height: 60px;    
    filter: drop-shadow( 0px 0px 2px rgb(179, 151, 115) );
    border-radius: 20%;
    /* border: 0px solid rgb(197, 197, 197); */
}

.person_data_kubok_shine {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 100;

}