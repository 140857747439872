.ain-medal-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.medal-div {
  position: relative;
}

.medal-div-canvas {

}

.medal-div-with-count {
  width: 50%;
  height: 38%;

  border-radius: 15px;

  border: 2px solid rgb(105, 143, 150);
  background: linear-gradient(rgb(0, 13, 29), rgb(5, 56, 80));

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  top: 70%;
  left: 25%;

  position: absolute;
  color: white;
}

.medal-div-with-count > span {
  font-family: 'PFD';
  font-size: 320%;

}

.medal-div h1 {
  margin-top: 5vw;
  font-size: 4.5vw !important;
  line-height: 4vw !important;
  color: white !important;
  font-family: 'MyriadPro';
}

.medal-div h3 {
  margin-top: 0px;
  font-size: 2.5vw !important;
  line-height: 2vw !important;
  color: rgb(0, 123, 160) !important;
  font-family: 'MyriadPro';
}

/*      СТАТИСТИКА      */