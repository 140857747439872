.person_place_rect_mini {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background: linear-gradient( rgba(255, 255, 255, 0.651), rgba(255, 255, 255, 0.534));

    border-radius: 8%;
    background-position: center;
    position: relative;
    /* background-image: image(silver); */
    /* background-image: url('/public/images/ver2/textures/gold.jpg'); */
}

.person_place_rect_mini_right {
    position: absolute;
    left: 55%;
    top: 5%;
    background: rgba(255, 255, 255, 0.795);
    padding: 4px;
    border: 1px solid gray;
    border-radius: 12px;
}

.person_place_rect_mini_upper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.person_place_rect_mini_downed {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;   

    font-family: 'PFD';
    text-transform: uppercase;
    font-weight: 600;

    font-size: 160%;
}

.main_center_container_mobile 
.person_place_rect_mini_downed {
    font-size: 70%;
}

.person_place_rect_mini_downed span {
    color: rgb(5, 56, 80);
    height: 25%;
    width: 99%;
    text-align: center !important;
}

.person_place_rect_mini_downed_data {
    width: 80%;
    font-size: 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;   
    margin-bottom: 3px;
}