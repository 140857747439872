.item_onwall_components {
    position: relative;
    width: 100%;
    height: 100%;
}

.item_onwall_components img {
    width: 100%;
    height: 100%;
}

.item_onwall_components_event {
    position: absolute;
}

.item_onwall_components_event_shine {
    position: absolute;
    top: -18px;
    left: -10px;
}