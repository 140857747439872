.ui_button_plashka {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(rgb(235, 235, 235), white); */

    /* background-position: center; */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* border: 1px solid gray; */

    cursor: pointer;
    transition-duration: 0.3s;

}

.ui_button_plashka:hover {
    background: rgb(0, 91, 133);
}

.ui_button_plashka_type_dashed {
    background: rgb(105, 151, 165);
}

.ui_button_plashka_type_danger {
    background: rgb(184, 33, 28) !important;
}
.ui_button_plashka_type_danger:hover {
    background: rgb(129, 7, 3) !important;
}

.ui_button_plashka_type_disabled {
    background: rgb(114, 114, 114) !important;
    cursor: not-allowed;
}

.ui_button_plashka_type_disabled:hover {
    background: rgb(114, 114, 114);
    cursor: not-allowed;
}

.ui_button_plashka_text {
    width: fit-content;
    height: fit-content;

    font-size: 15px;
    color: white;
    font-family: 'PFD';
}