.item_inventar_components {
    
    border-radius: 15%;
    /* overflow: hidden; */
    background: linear-gradient(rgb(206, 206, 206), white);

    
    background: rgb(141, 153, 160);
    border-radius: 5px;
    position: relative;

}

.item_inventar_components_infomodal {
    background: none;
}

.item_inventar_components img {
    width: 100%;
    height: 100%;
}

.item_inventar_components_type {
    position: absolute;
    width: 35%;
    height: 35%;

    top: 60%;
    left: 5%;    

    border-radius: 50%;
    background: rgba(255, 255, 255, 0.349);
}

.item_inventar_components_last {
    position: absolute;
    width: 35%;
    height: 20%;

    top: 70%;
    left: 55%;    

    text-align: center;
    font-family: 'PFD';

    border-radius: 15%;
    color: white;
    background: rgba(1, 75, 109, 0.493);  
    filter: drop-shadow(1px 2px 2px black);
}

.item_inventar_components_last_ended {
    background: rgba(236, 0, 0, 0.493);  
}

.item_inventar_components_type img {
    width: 100%;
    height: 100%;
}

.item_inventar_components_used {
    border: 5px solid rgb(0, 46, 65);
}

.item_inventar_components_used_clickable {
    cursor: pointer;
}

.item_inventar_components_event {
    position: absolute;
}

.item_inventar_components_upper {
    position: absolute;
    top: 0px;
    left: 100%;
    width: 0%;
    height: 100%;
    background: rgba(0, 46, 65, 0.753);
    transition-duration: 0.4s;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-size: 30px;
    color: white;

    column-gap: 20px;
    overflow: hidden;

}

.item_inventar_components:hover 
.item_inventar_components_upper {
    
    position: absolute;
    left: 0px;
    width: 100%;
}

.item_inventar_components_event_shine {
    position: absolute;
}