.group_visits_main_best {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.group_visits_main_best 
.raiting_big_plashka__best {
    column-gap: 20px;
    flex-wrap: wrap;
    row-gap: 10px;
}

.main_center_container_mobile 
.raiting_big_plashka__best {
    width: 98vw;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    flex-wrap: wrap;
    row-gap: 10px;

}

.group_visits_main_best_best {
    width: 100%;
}
.group_visits_main_best_table {    
    width: 90%;
}


.main_center_container_mobile 
.group_visits_main_best_table {
    width: 94%;
}

.group_visits_main_best
.raiting_big_plashka__oneraiting {
    background: white;
    color: black;
    font-family: 'Uni';
}

.group_visits_main_best
.raiting_big_plashka__best {
    background: none;
}

.group_visits_main h3 {
    font-size: 30px;
    font-family: 'DIN';
}

.group_visits_main_months {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    margin-top: 25px;
}

.group_visits_main > span {
    font-size: 16px;
    font-family: 'DIN';
    margin-right: 10px;
}