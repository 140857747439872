.event_block_admission {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 60px;
    height: 60px;

    border: 1px solid rgb(231, 231, 231);


    border-radius: 5px; 
}

.event_block_admission_check {
    background: linear-gradient(rgb(224, 252, 224), lightgreen);
    border: 2px solid rgb(46, 199, 46);
}

.event_block_admission_cross {
    background: linear-gradient(rgb(252, 233, 224), rgb(238, 144, 144));
    border: 2px solid rgb(184, 28, 28);
}

.event_block_admission_upper {
    height: 50%;
}

.event_block_admission_downed {
    font-size: 13px;
    height: 50%;
    line-height: 13px;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main_center_container_mobile .event_block_admission_downed {
    font-size: 9px;
    height: 50%;
    line-height: 9px;
}

.event_block_admission_clicked {
    cursor: pointer;
}