.person_quests_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    /* background: rgb(255, 255, 255); */
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;
    
    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 12px;   

    row-gap: 5px;

    overflow-y: visible;

}

.person_quests_plashka_inner {
    display: flex;
    flex-direction: column;
    height: fit-content;
    row-gap: 5px;
    width: 100%;
}