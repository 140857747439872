.pumpcin_hw img {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
}

.pumpcin_hw_abs {
    position: absolute;
    z-index: 1200;
}