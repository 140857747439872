.card-kukinhero-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: none;

    color: white;

    border: 1px solid rgba(128, 128, 128, 0.253);
}

.card-kukinhero-cover img {
    width: 100%;
    height: 100%;
}

.card-kukinhero-cover:hover img {
    filter: hue-rotate(-30deg);
}

.card-kukinhero-content {

    width: 100%;
    height: 100%;
    
}

.card-kukinhero-content-logo-img {

    position: absolute;
    width: 75%;

    top: 1%;
    left: 20%;
    
    /*filter: invert( 100% ) hue-rotate(180deg);*/


}

.card-kukinhero-content-logo-img img {
    width: 100%;
    height: auto;  
    filter: unset !important;
}

.card-kukinhero-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-kukinhero-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-kukinhero-content-title {

    position: absolute;
    width: 90%;
    text-align: center;

    top: 79%;   
    left: 5%;

    font-size: 1vw;

    font-family: 'PT Sans';
    text-transform: uppercase;

    filter: drop-shadow(-3px -3px 0px black) drop-shadow(3px 3px 0px black) drop-shadow(0px 0px 5px black);

    font-weight: bold;

    color: white;

}

.card-kukinhero-content-tier {
    position: absolute;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    top: 66%;

    font-size: 120%;

    font-family: 'Bewlay';
    text-transform: uppercase;  

    font-weight: bold;

    filter: drop-shadow(-3px -3px 0px black) drop-shadow(3px 3px 0px black) drop-shadow(0px 0px 5px black);

}