.person_inventar_container {

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;

    /* font-family: 'Uni'; */
    color: rgb(5, 56, 80);

    font-family: 'PFD';

    width: 100%;
    height: fit-content;
    /* min-height: 300px; */
}

.person_inventar_container_filter {
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.person_inventar_container_mobile
.person_inventar_container_filter
{
    width: 100px;
}

.person_inventar_container_filter_types {
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    row-gap: 4px;
}

.person_inventar_container_mobile
.person_inventar_container_filter_types {
    width: 30px;
}


.person_inventar_container_filter_type {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 20%;

    background: none;
    transition-duration: 0.5s;
    cursor: pointer;
}

.person_inventar_container_filter_type:hover {
    background: rgb(212, 212, 212);
}

.person_inventar_container_filter_type_selected {
    background: rgb(182, 182, 182);
}

.person_inventar_container_items {
    /* background: linear-gradient(rgb(145, 145, 145), rgb(172, 172, 172)); */
    border-radius: 5px;

    width: 760px;
    height: 500px;
    padding-top: 10px;

    overflow-y: auto;

}

.person_inventar_container_mobile 
.person_inventar_container_items {
    height: 350px;
}

.person_inventar_container_items_inside {
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    align-items: flex-start;
    flex-wrap: wrap;

    width: 100%;
    height: fit-content;

    row-gap: 12px;
    column-gap: 10px;
}

.person_inventar_blank_item {
    background: rgb(141, 153, 160);
    border-radius: 5px;
}
