.raiting_big_plashka{
    width: 100%;
    height: 100%;
    padding-top: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    font-family: 'MyriadProUsual';

    /* background:  white;
    padding: 20px;
    border-radius: 5px; */

    overflow-y: auto;
    overflow-x: hidden;


}

.main_center_container_mobile
.raiting_big_plashka {
    padding: 0px;
}


.raiting_big_plashka__best { 
    width: 100%;
    height: fit-content;
    padding: 20px;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    
    background: rgb(5, 56, 80);
    background: linear-gradient(rgb(0, 16, 26), rgb(5, 56, 80));

}

.raiting_big_plashka__oneraiting {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 55px;
    min-height: 55px;
    
    /* background: rgb(243, 243, 243);
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */

    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(207, 207, 207);

    transition-duration: 0.5s;
    font-family: 'PFD';
    font-weight: 100;
    text-transform: uppercase;

}

.raiting_big_plashka__oneraiting_row {
   animation: 0.7s one_row_anim forwards ease-in-out;
}

@keyframes one_row_anim {
    from {
        margin-left: 30px;
        opacity: 0;
    }
    to {
        margin-left: 0px;
        opacity: 1;
    }
}


.raiting_big_plashka__oneraiting_row_selected,
.raiting_big_plashka__oneraiting_row:hover {
    background: white;
}



.raiting_big_plashka__oneraiting_header {
    margin-top: 15px;
    height: 30px;
    min-height: 30px;
    font-family: 'Uni';
    border-bottom: 1px solid rgb(110, 110, 110);
}

.raiting_big_plashka__oneraiting_place {
    width: 120px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.raiting_big_plashka__oneraiting_header
.raiting_big_plashka__oneraiting_place {
    justify-content: flex-start;

    padding-left: 10px;
}

.raiting_big_plashka__oneraiting_place_inside {
    width: 45px;
}

.main_center_container_mobile 
.raiting_big_plashka__oneraiting_place_inside {
    width: 25px;
}

.raiting_big_plashka__oneraiting_category {
    width: 100%;
    padding-left: 20px;
}

.raiting_big_plashka__oneraiting_scores {
    width: 100px;
}

.raiting_big_plashka__raitingtitle {
    font-family: 'PFD';
    font-size: 20px;
    text-align: center;
    margin-bottom: 5px;
}

.main_center_container_mobile 
.raiting_big_plashka__raitingtitle {
    font-size: 14px;
}