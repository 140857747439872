.person_registrtion {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin-left: 10px; */
    /* font-family: 'Uni'; */
    color: rgb(5, 56, 80);

    
    font-family: 'PFD';

}

.person_registrtion_tabs .ant-tabs-tab {
    color: rgb(5, 56, 80);
    text-transform: uppercase;
}

.person_registrtion_tabs .ant-tabs-tab-active {
    color: #1890ff;
}

.person_registrtion_tabs .ant-tabs-left-content {
    color: black;
}

.person_registrtion_all {
    display: flex;
    flex-direction: column;

    font-size: 16px;
    font-family: 'PFD';
    color: rgb(5, 56, 80);
}