.admin_modal {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
    /* font-family: 'Uni'; */
    color: rgb(5, 56, 80);

    
    font-family: 'PFD';

}

.admin_modal_tabs .ant-tabs-tab {
    color: rgb(5, 56, 80);
    text-transform: uppercase;
}

.admin_modal_tabs .ant-tabs-tab-active {
    color: #1890ff;
}

.admin_modal_tabs .ant-tabs-left-content {
    color: black;
}

.add_event_result {
    font-size: 18px;
    text-transform: none;
}

.payment_table_row {
    display: flex;
    flex-direction: row;
    column-gap: 5px;

    font-size: 17px;
    height: 30px;
}

.payment_table_header {
    font-family: 'MyriadPro';
    font-size: 16px;
    height: 20px;
    margin-top: 10px;
    border-top: 1px solid rgb(216, 216, 216);
    color: rgb(5, 56, 80);
    font-weight: bold;
}

.payment_table_first_column {
    width: 150px;
    font-size: 19px;
}

.payment_table_second_column {
    width: 150px;
    font-size: 16px;
}

.payment_table_payment_column {
    width: 110px;
    text-align: center;
}