.cordao-div {
}

.cordao_svg_front {
    opacity: 0.4;
}

.cordao-div-blank {
    opacity: 0.2;
}

.cordao_svg_back {
    opacity: 0.6;
}
