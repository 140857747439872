.purpose_component {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
    background: none;
}

.purpose_search_conatiner {
    width: 130px;
    height: 22px;
    position: relative;
}

.purpose_search_conatiner__input {
    width: 100%;
    height: 100%;
}

.purpose_search_conatiner .ant-input {
    height: 20px !important;
}

.purpose_search_conatiner__results {
    width: 220px;
    min-height: 50px;
    position: absolute;

    padding: 5px;
    background: white;
    border-radius: 10px;

    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );

    top: 30px;
    left: -20px;

    z-index: 1000;

}

.purpose_search_conatiner__results_close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    left: calc(100% - 30px);

    font-size: 15px;
    opacity: 0.5;
    cursor: pointer;

    transition-duration: 0.5s;
    z-index: 3;

}

.purpose_search_conatiner__results_close:hover{
    opacity: 1;
    transform: scale(1.2);
}