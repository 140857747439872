.global_title {
    height: 60px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    /* font-family: 'Uni'; */
    color: rgb(5, 56, 80);

    
    font-family: 'PFD';
    text-transform: uppercase;
    font-weight: bold;

}

.global_title_text_clicked {
    cursor: pointer;
}

.title_plashka_content {
    /* overflow: hidden; */
}
