.scores_component {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
    background: none;
}

.scores_component img {
    width: 90%;
    height: auto;
    background: none !important;
}

.scores_component_upper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.scores_component .scores_component_amount {
    font-family: 'Uni';
    font-size: 14px;
}