.school_place_rect_mini {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background: linear-gradient( rgba(255, 255, 255, 0.651), rgba(255, 255, 255, 0.534));

    border-radius: 15px;
    background-position: center;
    /* background-image: image(silver); */
    /* background-image: url('/public/images/ver2/textures/gold.jpg'); */
}

.school_place_rect_mini_upper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.school_place_rect_mini_downed {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;   

    font-family: 'PFD';
    text-transform: uppercase;
    font-weight: 700;

    font-size: 180%;
}

.main_center_container_mobile 
.school_place_rect_mini_downed {
    font-size: 70%;
}

.school_place_rect_mini_downed span {
    color: rgb(5, 56, 80);
    height: 25%;
    text-align: center;
    line-height: 27px;
}

.school_place_rect_mini_downed_data {
    width: 80%;
    font-size: 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;   
    margin-bottom: 3px;
}