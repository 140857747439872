
.group_results_plashka {
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-content: flex-start;

    position: relative;

    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    border-radius: 10px; */
    
    row-gap: 5px;

}

.group_results_plashka_season {
    margin-top: 10px;
    font-family: 'UNI';
    color: rgb(0, 57, 83);
}