.notification-plashka-content {
    width: 100%;
    padding: 5px;
    height: fit-content;

    border-bottom: 1px solid rgb(180, 180, 180);
    background: none;

    transition-duration: 0.2s;

    position: relative;
}

.notification-plashka-content_text {
    max-height: 30px;
    width: 100%;
    overflow: hidden;
}

.notification-plashka-content_texttooltip .person_includes_text {
    color: rgb(187, 241, 239) !important;
}

.notification-plashka-content_texttooltip .person_includes_text {
    color: rgb(187, 241, 239) !important;
}

.notification-plashka-content_texttooltip a {
    color: rgb(187, 241, 239) !important;
}
.notification-plashka-content_texttooltip a:hover {
    color: rgb(145, 192, 190) !important;
}

.notification-plashka-content:hover {
    background: rgb(246, 255, 255);
}

.notification_plashka_content_delete {
    width: 25px;
    height: 25px;

    position: absolute;
    top: calc(5px);
    left: calc(100% - 30px);

    background: rgb(246, 255, 255);
    border-radius: 3px;
    visibility: visible;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

}

/* .ant-tooltip-placement-bottom
.ant-tooltip-inner {
    background: white !important;
} */

/* .notification-plashka-content:hover .notification_plashka_content_delete {
    visibility: visible;
} */