.school_big_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    background: rgb(243, 243, 243);
    display: flex;
    flex-direction: row;
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    position: relative;
    overflow: hidden;
}

.school_big_plashka___avatar_back {
    position: absolute;
    opacity: 0;
    transform: scale(1.8);
    left: -30%;
    animation: 'school_avatar_back' 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

@keyframes school_avatar_back {
    from {
        left: -30%;
        opacity: 0;
    }
    1% {
        opacity: 0;
    }
    to {
        left: -10%;
        opacity: 0.2;
    }
}

.school_big_plashka___avatar_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;  
}

.school_big_plashka___info_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    position: relative; 
}

.school_big_plashka___info_container___name {
    text-align: left; 
    width: 100%;
    height: 14%;
    min-height: 14%;
    font-size: 47px;
    font-family: 'MyriadPro';
    margin-top: 40px;
}

.main_center_container_mobile .school_big_plashka___info_container___name {
    height: 30px;
    min-height: 30px;
    font-size: 31px;
    margin-top: 16px;
}


.school_big_plashka___info_container___info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;  
    width: 100%;
    height: 12%;
    min-height: 12%;
    font-size: 22px;
    font-family: 'MyriadPro';
}

.school_big_plashka___info_container___school_group_cordao {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;  
    width: 100%;
    height: 66%;
    min-height: 66%;
}

.school_big_plashka___info_container___school_group_cordao 
.rectAvatar {
    margin-right: 40px;
}