.main-container {
    width: 100%;
    height: fit-content;
    min-height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    position: relative;

    background: linear-gradient(rgb(0, 37, 48), black);

}

.main-backimage {

    position: absolute;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 20vw;

    background: unset;
    z-index: 0;

    
    background-size: cover !important;
    background-position: center !important;

}

.main-center-container {
    max-width: 1200px;
    width: 1200px;
    height: fit-content;
    min-height: 400px;

    background: rgb(245, 243, 243);
    /* background: white; */
    color: black;
    z-index: 1;
    /* z-index: 200; */
    margin-top: 30px;
}

.main_center_container_mobile {
    max-width: 100vw;
    width: 100vw;
    height: fit-content;
    min-height: 400px;

    background: rgb(245, 243, 243);
    /* background: white; */
    color: black;
    z-index: 1;
    margin-top: 30px; 

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    margin-top: 0px;
}

.ant-drawer-body {
    padding: 12px !important;
}

.main_center_container_mobile .trefa_plashka__ {
    align-items: flex-start !important;
}

