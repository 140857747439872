.avatar_container {
    position: relative;
}

.avatar_container_achivment {
    position: absolute;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar_container_achivment img {
    width: 100%;
    height: 100%;
    vertical-align: 0%;
    position: absolute;
    top: 0px;
    left: 0px;
}

.avatar_container_raiting {
    background: rgba(255, 255, 255, 0.952);
    border-radius: 4px;
    border: 1px solid gray;
    height: fit-content;
    min-width: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    font-family: 'MyriadPro';
    font-weight: bold;
}

.avatar_container_raiting_pro {
    color: rgb(57, 70, 255);
}

.avatar_container_raiting_notpro {
    color: rgb(0, 165, 8);
}

.avatar_container_achivment_shine {
    position: absolute;
}