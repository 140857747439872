.school_raiting_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    background: rgb(243, 243, 243);
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    border-radius: 15px;
}

.school_raiting_plashka-switch-item {
    cursor: pointer;
    color: gray;
    margin-left: 10px;
}


.main_center_container_mobile 
.school_raiting_plashka-switch-item {
    font-size: 12px;
}

.school_raiting_plashka-switch-item__selected {
    color: rgb(5, 56, 80);
}

.school_raiting_plashka__oneraiting {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.school_raiting_plashka__oneraiting__place_container {
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Uni';
    /* background: linear-gradient(rgb(0, 13, 29), rgb(5, 56, 80)); */
    color: rgb(0, 0, 0);
}

.school_raiting_plashka__oneraiting__place_container_left {
    height: 90%;
    border-radius: 20%;
    text-align: center;
    line-height: 130%;
    margin-right: 5%;
}

.school_raiting_plashka__oneraiting__place_container_middle {    
    line-height: 120%;
}
.school_raiting_plashka__oneraiting__place_container_down
{
    height: 20%;
}

.school_raiting_plashka__oneraiting__place_container_left_place_1 {
    border: 2px solid gold;
    background: linear-gradient(rgb(255, 136, 0), gold);
    color: rgb(255, 255, 255)
}

.school_raiting_plashka__oneraiting__place_container_left_place_2 {
    border: 2px solid rgb(255, 255, 255);
    background: linear-gradient(rgb(255, 255, 255), rgb(205, 214, 216));
    color: rgb(92, 92, 92)
}

.school_raiting_plashka__oneraiting__place_container_left_place_3 {
    border: 2px solid #cd7f32;
    background: linear-gradient(rgb(248, 150, 37), #cd7f32);
    color: rgb(255, 255, 255)
}