.person_big_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );

    border-top-left-radius: 30px;

    overflow: hidden;

    position: relative;

    border-radius: 40px;
    
}

.person_big_plashka_rank_gold {
    background: linear-gradient(rgb(255, 153, 1), rgb(58, 0, 44));
    filter: drop-shadow( 0px 0px 20px rgba(255, 225, 89, 0.767) );
}

.person_big_plashka_rank_silver {
    background: linear-gradient(rgb(194, 193, 193), rgb(82, 82, 82));
    filter: drop-shadow( 0px 0px 5px rgb(255, 255, 255) );
    border: 6px solid white;
}

.person_big_plashka_rank_bronze {
    background: linear-gradient(rgb(230, 159, 119), rgb(216, 93, 35));
    filter: drop-shadow( 0px 0px 5px rgb(245, 177, 132) );
    border: 2px solid rgb(241, 145, 107);
}

.bgbgbg {
    position: absolute;
    top: 0px;
    left: 0px;
}

.person_big_plashka___avatar_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;  
}

.person_big_plashka___info_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start; 
    position: relative; 
}

.person_big_plashka___info_container___name {
    text-align: left; 
    width: 100%;
    /* height: 14%; */
    /* min-height: 14%; */
    font-size: 34px;
    font-family: 'MyriadPro';
}

.person_big_plashka___info_container___info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;  
    width: 100%;
    height: 14%;
    min-height: 14%;
    font-size: 22px;
    font-family: 'MyriadPro';
    column-gap: 8px;
}

.person_big_plashka___info_container___school_group_cordao {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;  
    width: 100%;
    height: 66%;
    min-height: 66%;
}

.person_big_plashka___info_container___school_group_cordao 
.rectAvatar {
    margin-right: 40px;
}