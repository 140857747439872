.ui_item__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ui_item__icon {
    height: 50%;
    width: 50%;
}

.ui_item__icon img {
    width: 100%;
    height: 100%;
}

.ui_item__text {
    margin-top: 5px;
    height: 20%;
    text-align: center;
    font-family: 'MyriadPro';
    color: rgb(71, 71, 71);
}
