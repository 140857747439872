.person_little {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgb(196, 196, 196);
    /* background: rgb(0, 28, 51); */
    /* background: linear-gradient(rgb(0, 29, 46), rgb(1, 43, 68)) */
    background: rgb(255, 255, 255);

    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    margin-left: 0px;

    /* image-rendering: pixelated; */
    /* image-resolution: 3200; */
    image-rendering: optimizeSpeed;

}

.person_little_clickable {
    cursor: pointer;
}

.person_little_clickable:hover {
    background: rgb(183, 255, 219);
}

.person_little__sign {
    border-radius: 50%;
}

.person_little_categorys {
    column-gap: 10px;
}

.main_center_container_mobile
.person_little_categorys {
    column-gap: 5px;
}

.person_little_results {
    column-gap: 10px;
    background: none;
}

.main_center_container_mobile
.person_little_results {
    column-gap: 2px;
}

.person_little_category_name {
    height: fit-content;
    font-family: 'PFD';
    font-size: 22px;
}

.main_center_container_mobile
.person_little_category_name {
    font-size: 16px;
}

.person_little_results_name {
    height: fit-content;
    font-family: 'PFD';
    font-size: 22px;
    min-width: 218px;
    max-width: 218px;
    line-height: normal;
} 

.main_center_container_mobile
.person_little_results_name {
    font-size: 14px;
    min-width: 130px;
    max-width: 130px;
}

.person_little_students_name {
    height: fit-content;
    font-family: 'PFD';
    font-size: 22px;
    min-width: 350px; 
    max-width: 350px;

    line-height: 20px;

    overflow: visible;
}

.person_little_results_place {
    min-width: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

.main_center_container_mobile 
.person_little_results
.person_little_results_place {
    min-width: 50px;
}

.main_center_container_mobile 
.person_little_results_place {
    min-width: 100px;
}

.person_little_results_rank_mobile {
    min-width: 30px;
    max-width: 30px !important;
    width: 30px !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
}

.person_little_students_rank {
    min-width: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.main_center_container_mobile 
.person_little_students_rank {
    min-width: 30px;
}

.person_little_results_race {
    min-width: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: green;
    margin-left: 5px;

    font-size: 12px;
    font-family: 'PFD';
    font-weight: bold;
}
.person_little_results_race img {
    width: 15px;
    height: auto;
}

.main_center_container_mobile 
.person_little_results_race img {
    width: 10px;
    height: auto;
}

.main_center_container_mobile 
.person_little_results_race {
    font-size: 10px;
}

.person_little_results_down {
    color: red;
}

.person_little:last-child {
    border-bottom: none;
}

.person_little_loading {
    background: none;
    opacity: 0.5;
    margin-left: 20px;
}

.person_little__avatar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.person_little__cordao {
    position: absolute;
    top: 50%;
    left: 45%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

}

.person_little__fio {
    width: 100%;
    height: fit-content;
    font-family: 'MyriadPro';
    margin-bottom: 15px;
    /* color: white; */
}

.person_little__fio_student {
    width: 120px;
    max-width: 120px;
    height: fit-content;
    font-family: 'MyriadPro';
    margin-bottom: 15px;
    white-space: nowrap;
    overflow: visible;
}

.person_little__suffix {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
}

.person_little__suffix_gi {
    padding-right: 20px;
    font-family: 'PFD';
    font-size: 19px;
}

.person_little__school {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.person_little .person_little_students_change {
    visibility: hidden;
    position: absolute;
    opacity: 0;
    margin-left: auto;
    transition-duration: 0.5s;
    transition-property: 'opacity';

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgb(189, 187, 187);
    background: white;
    padding-left: 10px;
    column-gap: 10px;
    width: 0px;
    max-width: 0px;
    left: 100%;
    transform: translateX(-110%);

}

.person_little:hover .person_little_students_change {
    visibility: visible;
    opacity: 1;
    cursor: pointer;
    padding-right: 10px;
    width: fit-content;
    max-width: fit-content;
    min-width: 50px !important;
}


.person_little_category_admin_move {
    opacity: 0;
    visibility: hidden;
    font-family: 'MyriadPro';
    font-size: 14px;
    cursor: pointer;
}

.person_little:hover
.person_little_category_admin_move {
    opacity: 1;
    visibility: visible;
    color: rgb(0, 32, 75);
}

/* /////////////////////// VIDEO //////////////////////// */

.person_little_videoplate {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: none;

    animation-name: videoPlateRight;

    transition: none;

    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-delay: 0.7s;
    
    animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);

    opacity: 0.0;

    

}

.person_little_videoplate_left {
    animation-name: videoPlateLeft;

    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    opacity: 0.0;
    animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);

    margin-left: -30%;
}

@keyframes videoPlateLeft {
    from {
        margin-left: -30%;
        opacity: 0.0;
    }
    70% {
        margin-left: 10%;
        opacity: 1.0;
    }
    to {
        margin-left: 0%;
        opacity: 1.0;
    }
}
@keyframes videoPlateRight {
    from {
        margin-left: 30%;
        opacity: 0.0;
    }
    70% {
        margin-left: -10%;
        opacity: 1.0;
    }
    to {
        margin-left: 0%;
        opacity: 1.0;
    }
}



.person_little_videoplate_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;   
    height: 100%;
    width: 100%;
}

.person_little_videoplate_left
.person_little_videoplate_right {
    align-items: flex-end;   
}

.person_little_videoplate_right_name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;   
    height: 30%;
    font-family: 'Uni';
    color: rgb(0, 35, 65);
    padding-left: 10px;
    /* width: 100%; */
    z-index: 1;
    text-align: left;
}

.person_little_videoplate_left 
.person_little_videoplate_right_name {
    align-items: flex-end;   
    padding-left: 0px;
    padding-right: 10px;
    text-align: right;
}

.person_little_videoplate_right_stuff {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;   
    height: 40%;
    column-gap: 20px;
    width: 100%;
    padding-left: 10px;
    z-index: 1;
}

.person_little_videoplate_left
.person_little_videoplate_right_stuff {
    justify-content: flex-end;
    padding-left: 0px;
    padding-right: 10px;
}



.person_little_videoplate_back {
    position: absolute;
    z-index: 0;
    opacity: 0.2;
    top: -25%;
    left: 60%;

    animation-name: videoPlateSchoolRight;

    animation-duration: 0.7s;
    animation-fill-mode: forwards;
    animation-delay: 2s;
    opacity: 0.0;

}

.person_little_videoplate_left 
.person_little_videoplate_back {
    position: absolute;
    z-index: 0;
    opacity: 0.2;
    top: -25%;
    left: -10%;

    animation-name: videoPlateSchoolLeft;

    animation-duration: 0.7s;
    animation-fill-mode: forwards;
    animation-delay: 3s;
    opacity: 0.0;

}

@keyframes videoPlateSchoolLeft {
    from {
        margin-left: -30%;
        opacity: 0.0;
    }
    to {
        margin-left: 0%;
        opacity: 0.2;
    }
}
@keyframes videoPlateSchoolRight {
    from {
        margin-left: 30%;
        opacity: 0.0;
    }
    to {
        margin-left: 0%;
        opacity: 0.2;
    }
}

.person_little_video_trefatext span {
    cursor: pointer;
    color: white;
}

.person_little_video_trefatext span:hover {
    color: rgb(1, 112, 163);
}

.person_little__contact {
    font-family: 'MyriadPro';
    margin-left: 9px;
    white-space: nowrap;
}

.person_little__suffix_bets {
    margin-left: auto;
    font-size: 14px;
    font-family: 'PFD';
    line-height: 13px;
    text-align: right;
    min-width: 80px;
}

/* .main_center_container_mobile */
.person_little_category_name_bets {
    font-size: 17px !important;
    line-height: 15px;
}