.article_page_main {
    width: 100%;
    min-height: 350px;
    display: flex;
    flex-direction: row;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 20px;
    padding-top: 0px;

    position: relative;
    justify-content: space-between;
}

.main_center_container_mobile .article_page_main {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
    padding-top: 0px;

    justify-content: flex-start;
    align-items: center;
}

.article_page_main_left {
    width: 750px;
    display: flex;
    flex-direction: column;
}

.article_page_main_right {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding-top: 30px;
    row-gap: 20px;
}

.article_page_main_right_articles {
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid rgb(206, 205, 205);
    border-radius: 10px;
    overflow: hidden;
}

.article_page_main_right_article_title {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;

    background: #053850;
    color: white;
    font-family: 'DIN';
    font-size: 18px;
}

.article_page_main_right_article {
    width: 90%;
    border-bottom: 1px solid rgb(192, 212, 218);
    margin-top: 4px;
    margin-bottom: 4px;
}

.article_page_main h1 {
    width: 100%;
    font-family: 'DIN';
    font-size: 60px;
    color: #053850;
    margin-bottom: 0px;
    height: 75px;
}

.main_center_container_mobile .article_page_main h1 {
    width: 100%;
    font-family: 'DIN';
    font-size: 50px;
    color: #053850;
    margin-bottom: 0px;
    line-height: 50px;
    height: fit-content
}

.article_page_main_discription {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 20px;

    font-family: 'MyriadPro';
    color: gray;
    font-size: 20px;
}

.article_page_main_text {
    margin-top: 10px
}

.article_page_main_right_article:last-child {
    border-bottom: none;
}

.article_little_container {
    padding-top: 5px;
    padding-bottom: 5px;
}

.article_little_container a {
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    justify-content: flex-start;
    align-items: center;
}

.article_little_container a span {
    text-decoration: dotted;
}