.person_wall {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    /* background: rgb(255, 255, 255); */
    display: flex;
    flex-direction: row;
    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */
    /* padding-top: 20px; */
    /* border-radius: 30px; */

    position: relative;
    overflow: hidden;
}

