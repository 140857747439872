.geks_container_svg {
    position: relative;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.geks_container_svg svg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: visible !important;
}

.geks_container_title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.geks_container_title span {
    color: white;
    font-family: 'Rubik';
    font-size: 12px;
    text-align: center;
    line-height: 14px;
}

.geks_container_title_low {
}

.geks_container_title_low span {
    font-size: 7px;
    text-align: center;
    line-height: 10px;
    margin-top: 5px;
}

.geks_container_svg_icon {
    position: relative;
    
}

.geks_container_svg_icon_gold path, .geks_container_svg_icon_gold polygon {
    fill: url(#grad_gold);
}

.geks_container_svg_icon_silver path, .geks_container_svg_icon_silver polygon {
    fill: url(#grad_silver);
}

.geks_container_svg_icon_bronze path, .geks_container_svg_icon_bronze polygon {
    fill: url(#grad_bronze);
}

.geks_container_svg img {
    position: relative;
    /* filter: invert(100%); */
}

.geks_container_blank {
    stroke: white;
    fill: none;
    stroke-width: 4%;
}

.geks_container_svg_text {
    position: relative;
    color: white;
    font-family: 'Rubik';
    text-align: center;
    font-size: 150%;
}

.geks_container_svg_text_gold {
    color: #580505;
}

.geks_container_svg_text_silver {
    color: #00284e;
}

.geks_container_svg_text_bronze {
    color: #773703;
}

rect { 
}