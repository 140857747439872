.group_structure_plashka {
    width: 100%;
    height: 100%;
    /* background: rgb(253, 253, 253); */

    display: flex;
    flex-direction: row;

    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */
    position: relative;
    overflow: hidden;
    /* overflow-x: hidden; */
    /* overflow-y: auto; */
}

.group_structure_plashka .persons_little_plashka {
    overflow-y: hidden;
}

.group_structure_plashka_modal {
    width: max(1100px, 90vw) !important;
}

.group_structure_plashka_modal .ant-modal-header {
    background: rgb(5, 56, 80);
}

.group_structure_plashka_modal .ant-modal-close-x {
    color: white;
}

.group_structure_plashka_modal .ant-modal-title {
    color: white;
    font-family: 'PFD';
    text-transform: uppercase;
}

.group_structure_plashka_modal .ant-modal-body {
    background: white;
    font-family: 'PFD';
    text-transform: uppercase;
}

.event_participation_decline_modal .ant-btn-primary {
    background: rgb(182, 0, 0);
    border: rgb(182, 0, 0);
}

.group_structure_plashka_filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
    padding-left: 10px;
    padding-bottom: 10px;
}

.group_structure_plashka_filter_one {
    cursor: pointer;
    font-family: 'PFD';
    font-size: 16px;
    color: gray;
}

.group_structure_plashka_filter_one_selected {
    font-weight: bold;
    color: rgb(5, 56, 80);
}

.group_structure_plashka_inner {
    height: fit-content;
    width: 100%;
}

.group_structure_plashka_students {
    width: 100% !important;
}