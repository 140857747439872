.person_enemys_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;
    
    padding-top: 10px;
    padding-bottom: 10px; 

    overflow: visible;

}

.person_enemys_plashka_one {
    width: 100%;
    cursor: pointer;
    transition-duration: 0.7s;
}
.person_enemys_plashka_one:hover {
    background: rgb(206, 215, 226);
}

.person_enemys_plashka_one_suffix {
    width: 120px;
    font-size: 15px;
    font-family: 'PFD';
    font-weight: bold;
    margin-left: 10px;
}

.person_enemys_plashka_one_suffix_even {
    color: rgb(0, 57, 83);
}

.person_enemys_plashka_one_suffix_win {
    color: rgb(18, 63, 0);
}

.person_enemys_plashka_one_suffix_lose {
    color: rgb(83, 26, 0);
}

.person_enemys_plashka
.person_little {
    background: none;
}

.person_enemys_plashka_bycompetitions {
    width: 100%;
    text-align: center;
    font-family: 'PFD';
    cursor: pointer;
}