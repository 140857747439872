.__status_default {
    padding-left: 5px;
    padding-right: 5px;

    height: 22px;
    background: rgb(148, 90, 4);
    border: 1px solid rgb(148, 90, 4);
    border-radius: 5px;
    color: white;
    position: relative;

    width: 90px;

    text-align: center;
}

.main_center_container_mobile .__status_default {

    transform: scale(0.7);

}

.event_big_plashka__status_text {
    width: 100%;
    height: fit-content;
    font-family: 'MyriadPro';
}


.event_big_plashka__status_not_published {
    background: rgb(179, 7, 7) !important;
    border: 1px solid rgb(212, 3, 3) !important;

    width: 120px !important;
    left: calc(100% - 140px) !important;
    cursor: pointer !important;
}

.event_big_plashka__status_registration_not_open {
    background: rgb(66, 15, 82) !important;
    border: 1px solid rgb(39, 5, 49) !important;

    width: 150px !important;
    left: calc(100% - 170px) !important;
}

.event_big_plashka__status_registration_open {
    background: rgb(0, 126, 6) !important;
    border: 1px solid rgb(0, 151, 20) !important;

    width: 150px !important;
    left: calc(100% - 170px) !important;
}

.event_big_plashka__status_registration_closed {
    background: rgb(185, 59, 0) !important;
    border: 1px solid rgb(212, 99, 46) !important;

    width: 150px !important;
    left: calc(100% - 170px) !important;
}

.event_big_plashka__status_past {
    background: rgb(0, 70, 117) !important;
    border: 1px solid rgb(0, 69, 148) !important;

    width: 90px !important;
    left: calc(100% - 110px) !important;
}

.event_big_plashka__status_online {

    width: 90px !important;
    left: calc(100% - 110px) !important;  

    animation-name: 'goinggo';
    animation-duration: 1s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

@keyframes goinggo {
    from {
        background: rgb(129, 0, 0);
        border: 1px solid rgb(255, 45, 45);
    }
    to {
        background: rgb(255, 0, 0);
        border: 1px solid rgb(250, 179, 179);
    }
}


.event_big_plashka__status_cliced .global-tooltip {
    cursor: pointer !important;
}

.event_big_plashka .global-tooltip::after {
    margin-left: -300px !important;
    /* margin-right: 100px !important; */
    margin-top: 20px;
}

.event_big_plashka__status_past .global-tooltip::after {
    margin-left: -100px !important;
    /* margin-right: 100px !important; */
    margin-top: 20px;
}

.__status_default {
    left: 0px !important;
}