.event_purpose_component {
    display: flex;
    flex-direction: column;

    width: 100%;
    min-height: 200px;
}

.event_purpose_component_header {
    font-size: 18px;
    font-family: 'UNI';

    color: rgb(0, 57, 83);
}

.event_purpose_component_purposer {
    width: 300px;
}