.rectAvatar {
    width: 40px;
    height: 60px;

    /* background: linear-gradient( rgba(245, 245, 245, 0.349), rgba(255, 255, 255, 0.534)); */
    /* border: 1px solid rgb(202, 202, 202); */
    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */

    display: flex;
    flex-direction: column;
    transition-duration: 1s;

    cursor: pointer;
    position: relative;
}

.rectAvatarSelected {
    filter: drop-shadow( 0px 0px 6px rgba(87, 215, 231, 0.801) )
}

.rectAvatar:hover {
    filter: drop-shadow( 0px 0px 6px rgba(255, 196, 0, 0.801) )
}

.rectAvatar_upper {
    width: 100%;
    height: 70%;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rectAvatar_lower {
    width: 100%;
    height: 30%; 
    max-height: 30%;  
    overflow: hidden; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rectAvatar_lower_text {
    color: black;
    font-size: 90%;
    font-family: 'Uni';
    text-align: center;
}

.rectAvatar_upper_absolute {
    position: absolute;
    color: rgb(94, 128, 167);
    font-family: 'Uni';
    width: 100%;
    top: 22%;
    left: 0%;
    text-align: center;
}