.event_avatar_main {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.event_avatar_main_extra {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.808);
    border: 1px solid white;
}