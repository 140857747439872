.person_elements_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    /* background: rgb(255, 255, 255); */
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: center;

    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */
    /* padding-top: 10px;
    padding-bottom: 10px; */
    border-radius: 12px;

    column-gap: 5px;

    overflow-y: visible;

}

.person_elements_plashka_upper {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: rgb(212, 211, 151);
}

.person_elements_plashka_upper_notallow {
    background: rgb(224, 224, 224);
}

.main_center_container_mobile 
.person_elements_plashka_upper {
    height: 35px;
}

.person_elements_plashka_middle {

    margin-top: 20px;
    width: 100%;

    display: flex;
    flex-direction: row;

    justify-content: space-around;
    align-items: center;
}

.main_center_container_mobile
.person_elements_plashka_middle {
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: center;
    height: 100%;
    overflow: auto;
}

.person_elements_plashka_downed {
    width: 100%;
    height: 50px;
    background: rgb(5, 56, 80);
    margin-top: 10px;
}

.person_elements_plashka_button_stat {
    height: 90%;
    width: 130px;
    font-family: "MyriadPro";
    color: rgb(61, 66, 71);
    font-weight: bold;

    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;

    border-radius: 10px;
    border: 1px dotted gray;
    background: rgb(196, 196, 196);

    transition-duration: 1s;

    font-size: 19px;
    text-align: center;
    line-height: 20px;
}

.main_center_container_mobile 
.person_elements_plashka_button_stat {
    width: 70px;
    font-size: 8px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.178);
}

.main_center_container_mobile
.global_info_text {
    /* font-size: 10px !important; */
    width: 20px;
    max-width: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.person_elements_plashka_button_stat:hover {
    background: white;
    background: rgb(5, 56, 80);
    color: white;
    cursor: pointer;
    transform: translateX(10%);
}

.personElementsModalClass {
    width: 1000px !important;
}
.personElementsModalClass
.ant-modal-header {
    height: 0px;
    max-height: 0px;
    padding: 0px;
    visibility: hidden;
}
.personElementsModalClass
.ant-modal-content {
    border-radius: 15px;
    background: radial-gradient(#306271, #0A2837);
}

.personElementsModalClassMobile {
    width: 1000px !important;
}
.personElementsModalClassMobile
.ant-modal-header {
    height: 0px;
    max-height: 0px;
    padding: 0px;
    visibility: hidden;
}

.personElementsModalClassMobile
.ant-modal-content {
    border-radius: 15px;
    background: radial-gradient(#306271, #0A2837);
}

.personElementsModalClassMobile
.ant-modal-body {
    padding: 10px !important;
}

/* ELEMENTS MODAL */

.person_elements_modal_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;

    row-gap: 4px;

    overflow-x: hidden;
}

.person_elements_modal_container_mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.person_elements_modal_container_left {
    width: 350px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.personElementsModalClassMobile 
.person_elements_modal_container_left {
    width: 95%;
}

.person_elements_modal_container_right {
    height: 650px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

/* DEVIDER */

.person_elements_modal_container_devider {
    color: white;
    font-family: 'Rubik';
    font-weight: 100 !important;
    font-size: 15px;
    width: 100%;
    height: 36px;
    min-height: 36px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    text-align: center;
}

.person_elements_modal_container_devider_crossed_left {
    height: 1px;
    background: white;
    width: 100%;
}
.person_elements_modal_container_devider_crossed_right {
    height: 1px;
    background: white;
    width: 100%;
}
.person_elements_modal_container_devider_crossed_center {
    height: fit-content;
    text-align: center;
    width: fit-content;
    white-space: nowrap;
    padding-left: 15px;
    padding-right: 15px;
}


.person_elements_modal_container_elementslist {

    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    row-gap: 3px;

}

.person_elements_modal_container_mobile 
.person_elements_modal_container_elementslist {
    row-gap: 1px;
}

.person_elements_modal_container_onerow {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: wheat;
}

.person_elements_modal_container_mobile 
.person_elements_modal_container_onerow {
    height: 40px;
}

.person_elements_modal_container_img {
    width: 50px;
    height: 50px;
    background: rgb(5, 56, 80);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.person_elements_modal_container_mobile
.person_elements_modal_container_img {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
}

.person_elements_modal_container_img_notgood {
    background: rgb(65, 34, 34);
}

.person_elements_modal_container_img_notused {
    background: rgb(110, 110, 110);
    background: rgb(64, 80, 94);
}

.person_elements_modal_container_img_notadded {
    background: rgb(40, 48, 54);
}

.person_elements_modal_container_img img {
    width: 90%;
    height: auto;
}

.person_elements_modal_container_title {
    width: 130px;
    font-size: 20px;
    font-family: 'MyriadPro';
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.person_elements_modal_container_title a {
    color: white !important;
}

.person_elements_modal_container_mobile
.person_elements_modal_container_title {
    width: 50px;
    min-width: 50px;
    font-size: 10px;
    line-height: 11px;
}

.person_elements_modal_container_title span {
    max-width: 80%;
    text-align: center;
    line-height: 14px;
}


.person_elements_modal_container_mobile
.person_elements_modal_container_title span {
    max-width: 80%;
    text-align: center;
    line-height: 11px;
}

.person_elements_modal_container_usedtime {
    width: 50px;
    font-family: 'UNI';
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.person_elements_modal_container_mobile
.person_elements_modal_container_usedtime {

    width: 35px;
    max-width: 35px;
    font-size: 12px;

}

.person_elements_modal_container_addelement {
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'MyriadPro';
    cursor: pointer;
}

.person_elements_modal_container_mobile
.person_elements_modal_container_addelement {
    width: 150px;
    max-width: 150px;

}

.person_elements_modal_container_addelement span {
    background: #9fcedb;
    width: 90%;
    border-radius: 2px;
    text-align: center;
    color: #0A2837;
    transition-duration: 0.7s;
}

.person_elements_modal_container_addelement span:hover {
    background: #6e9eac;
}

.person_elements_modal_container_usedtime_less08 {
    font-weight: bold;
    color:rgb(255, 66, 66);
}

.person_elements_modal_container_usedtime_08 {
    font-weight: bold;
    color:rgb(255, 127, 127);
}

.person_elements_modal_container_usedtime_09 {
    color:rgb(255, 174, 174);
}

.person_elements_modal_container_usedtime_10 {
    font-weight:normal;
    color: wheat;
}

.person_elements_modal_container_usedtime_11 {
    font-weight: bold;
    color:rgb(137, 229, 194);
}

.person_elements_modal_container_usedtime_12 {
    font-weight: bold;
    color:rgb(88, 168, 63);
}

.person_elements_modal_container_usedtime_13 span {
    color:white;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    background: linear-gradient(rgb(248, 150, 37), #855729);
    border-radius: 4px;
}

.person_elements_modal_container_usedtime_14 span {
    color:white;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    background: linear-gradient( rgb(128, 199, 195), rgb(22, 46, 70) );
    border-radius: 4px;
}

.person_elements_modal_container_usedtime_15 span {
    color:white;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    background: linear-gradient(rgb(255, 136, 0), gold);
    border-radius: 4px;
}

.person_elements_modal_container_options {
    
    height: 25px;
    width: 70px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    font-weight: bold;
    color: black;

    background: rgb(248, 248, 248);

    column-gap: 3px;
    padding-left: 7px;

    border-radius: 6px;
    border: 1px solid gray;
}

.person_elements_modal_container_tier_1 {
    color:rgb(161, 139, 12);
}
.person_elements_modal_container_tier_2 {
    color:rgb(0, 81, 255);
}
.person_elements_modal_container_tier_3 {
    color:rgb(141, 8, 8);
}

.person_elements_modal_container_options_strike
svg {
    fill: red !important;
}

.person_elements_modal_container_options_flow
svg {
    fill: rgb(21, 35, 114) !important;
}

.person_elements_modal_container_options_jump
svg {
    fill: rgb(0, 162, 255) !important;
}

.person_elements_modal_container_options_freeze
svg {
    fill: rgb(83, 36, 19) !important;
}

/* HEADER */

.person_elements_modal_container_header 
.person_elements_modal_container_img
{
    background: none;
}

.person_elements_modal_container_header span
{
    font-size: 12px !important;
    border: none !important;
    font-family: 'MyriadPro' !important;
    font-weight: bold !important;
    color: wheat;
}

.person_elements_modal_container_mobile
.person_elements_modal_container_header span
{
    font-size: 10px !important;
    text-align: center;
}

.person_elements_modal_container_header 
.person_elements_modal_container_options {
    border: none;
    background: none;
    font-family: 'MyriadPro';
}

#pemcor_header 
.person_elements_modal_container_usedtime {
    cursor: pointer;
}

#pemcor_header 
.person_elements_modal_container_usedtime_choosed
span
{
    color: wheat;
    color: #53BAE2;
}

/* SEQUENCES */
.person_elements_modal_container_sequences {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.person_elements_modal_container_sequence {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
}

.person_elements_modal_container_perfKoef {
    width: 40px;
    height: fit-content;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
}

.person_elements_modal_container_icon {
    cursor: pointer;
    transition-duration: 0.3s;
    transform: rotate(0deg);
    font-size: 20px;
}

.person_elements_modal_container_icon_checked {
    transform: rotate(90deg);
}

.person_elements_modal_container_row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

/* CARD */

.person_elements_modal_container_quest_container {
     width: 320px;
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: flex-start;
     column-gap: 10px;
     height: 150px;
}

.person_elements_modal_container_quest_container_icon {
    width: 120px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.person_elements_modal_container_quest_container_right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 190px;
}

.person_elements_modal_container_quest_container_title {
    font-family: 'UNI';
    color: white;
    font-size: 18px;
    height: 23px;
}

.person_elements_modal_container_quest_container_text {
    font-family: 'MyriadPro';
    color: white;
    font-size: 16px;
    width: 90%;
    height: 60px;
    line-height: 17px;
}

.person_elements_modal_container_quest_container_rest {
    font-family: 'Rubik';
    color: white;
    font-size: 10px;
    width: 100%;
    height: 20px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.person_elements_modal_container_quest_container_progress {
    width: 100%;
    height: 4px;
    background: rgb(19, 39, 77);
}

.person_elements_modal_container_quest_container_progress_inside {
    height: 4px;
    background: #53BAE2;
}

.person_elements_modal_container_row_cover {
    position: relative;
    width: 100%;
    height: 300px;
    min-height: 300px;
}

/* Это на страничке которая*/
.person_elements_plashka_upper
.person_elements_modal_container_row_cover {
    width: 40px;
    height: 30px;
    min-height: 30px;
}

.main_center_container_mobile
.person_elements_plashka_upper
.person_elements_modal_container_row_cover {
    width: 40px;
    height: 20px;
    min-height: 20px;
}

.person_elements_modal_container_row_cover > div {
    position: absolute;
    top: 15%;
    left: 26%;
    transform-origin: center bottom;
}


.person_elements_plashka_upper
.person_elements_modal_container_row_cover > div {
    position: absolute;
    top: -35%;
    left: 50%;
    transform-origin: center bottom;
}

.person_elements_modal_container_row_cover> div:nth-child(1) {
    transform: rotate(20deg) translateX(20%);
}
.person_elements_modal_container_row_cover> div:nth-child(2) {
    transform: rotate(-12deg) translateX(-20%);
}
.person_elements_modal_container_row_cover> div:nth-child(3) {
    transform:  scale(1.07) translateY(-5%) rotate(5deg);
}

.person_elements_modal_container_row_elements_add {
    cursor: pointer;
    height: 60px;
    min-height: 60px;
    background: #92b9c5;
    margin-top: 10px;
    font-family: 'MyriadPro';
    color: white;
    font-size: 18px;
    transition-duration: 1s;
}

.person_elements_modal_container_row_elements_add a {
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.person_elements_modal_container_row_elements_add:hover {
    background: #07313d;
}