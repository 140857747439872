.global_bg_main {
  background: rgb(5, 56, 80);
}

.global_color_main {
  color: #053850;
}

a {
	color: rgb(0, 57, 83) !important;
}

a:hover {
	color: rgb(1, 112, 163) !important;
}

.global_marginLeft {
  margin-left: 20px;
}

.global-tooltip {
	cursor: help;
}

.global-tooltip::after {
	background: rgba(0, 0, 0, 0.8);
	border-radius: 8px 8px 8px 0px;
	box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
	color: #FFF;
	content: attr(data-tooltip); /* Главная часть кода, определяющая содержимое всплывающей подсказки */
	margin-top: -44px;
	opacity: 0; /* Наш элемент прозрачен... */
	padding: 13px 17px;
	position: absolute;
	visibility: hidden; /* ...и скрыт. */
			
	transition: all 0.4s ease-in-out; /* Добавить плавности по вкусу */
}
		
.global-tooltip:hover::after {
	opacity: 1; /* Показываем его */
	visibility: visible;
}


.global-slide-from-right-loading {
	opacity: 0;
	margin-left: 30px;
}

.global-slide-from-right {
		animation-name: global-slide-from-right-animation;
		animation-duration: 0.7s;
		animation-fill-mode: forwards;
		animation-delay: 0.3s;
		opacity: 0;


}

@keyframes global-slide-from-right-animation {
	from {
		margin-left: 30px;
		/* transform: rotateY(-10deg); */
		opacity: 0;
	}
	to {
		margin-right: 0px;
		/* transform: rotateY(0deg);	 */
		opacity: 1;
	}
}

.global_plashka_no_data {

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: 'PFD';
    color: gray;
    font-size: 17px;
    padding: 30px;

	text-align: center;
}

.global_discribe_text {
    font-family: 'PFD';
	color: rgb(1, 33, 48);
	width: 100%;
	padding: 20px 40px;
	font-size: 21px;
}

.main_center_container_mobile
.global_discribe_text {
	padding: 12px 16px;
	font-size: 12px;
}

.global_discribe_mobile_text {
	padding: 12px 16px;
	font-size: 12px;
}

.global_info_text {
	font-family: 'PFD';
	font-style: italic;
	color: rgb(105, 105, 105);
	width: fit-content;
	padding: 10px 40px;
	padding-bottom: 5px;
	font-size: 19px;
	text-decoration-style: dotted;
	text-decoration-line: underline;
	text-decoration-skip-ink: auto;
	cursor: help;
}

.global_info_text_mini {
	padding: 5px 40px;
	padding-bottom: 2px;
	font-size: 16px;
}


.main_center_container_mobile 
.global_info_text {
	font-size: 15px;
}

.main_center_container_mobile 
.global_info_text_mini {
	font-size: 10px;
}

.global_info_text:hover {
	color: rgb(59, 59, 59);
}

.combo_scheme_plashka_inner {
    width: 100%;
    height: fit-content;
}

.solo_icon_checked {

	width: 20px;
	height: 20px;
  
	fill: yellow;
	stroke-width: 2;
	transition-duration: 2s;
	filter: drop-shadow(1px 1px 1px rgb(255, 174, 0)); 
  
	margin-right: 5px;
  
  }
