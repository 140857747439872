.social_icon_main {
    margin: 0px;
    padding: 0px;

    border-radius: 15%;

    background: rgb(0, 57, 83);
    display: flex;
}

.social_icon_main:hover {
    background: rgb(0, 91, 133);
}

.social_icon_main img {
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    height: 100% !important;
}