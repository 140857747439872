.kmo_quest_plashka {
    width: 100%;
    height: 100%;
    background: linear-gradient(rgb(235, 235, 235), white);

    /* background-position: center; */

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid rgb(226, 223, 223);

    overflow: hidden;

}

.kmo_quest_plashka_group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    font-family: "PFD" !important;
}


.main_center_container_mobile
.kmo_quest_plashka_groupname {
    font-size: 9px;
    text-align: center;
}

.kmo_quest_plashka_amount img {
    width: 20px;
    height: 20px;
}