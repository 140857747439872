.liketer_like {
    width: 32px;
    height: 32px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border: 2px solid rgb(83, 110, 110);
    border-radius: 7px;
    color: rgb(86, 119, 119);
    font-size: 22px;

    cursor: pointer;
    background: white;

    transition-duration: 0.5s;
}

.liketer_like:hover {
    background: rgb(193, 241, 225);
    border: 2px solid rgb(0, 59, 59);
    color: rgb(0, 59, 59);
    font-size: 25px;
}

.liketer_like_plashka_likes {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    font-family: 'DIN';
    font-size: 22px;
    transform: translateY(-10px);

    column-gap: 10px;
}


.main_center_container_mobile .liketer_like_plashka_likes {
    transform: translateY(0px);
}

.liketer_like_plashka_like_container {
    padding: 0px 10px;
    border: 1px solid rgb(84, 175, 211);
    color: rgb(83, 175, 211);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 4px;
    cursor: pointer;
    transition-duration: 0.7s;
}

.liketer_like_plashka_like_container1 {
    border: 1px solid white;
    color: white;
    background: linear-gradient(rgba(255, 255, 255, 0.233), rgba(128, 128, 128, 0.219))
}

.liketer_like_plashka_like_container2 {
    border: 1px solid rgb(255, 187, 0);
    color: rgb(255, 187, 0);
    background: linear-gradient(rgba(248, 253, 182, 0.363), rgba(255, 174, 1, 0.219))
}

.liketer_like_plashka_like_container_liked0 {
    background: rgb(117, 165, 177);
    color: #0B2349;
    border: 1px solid #0B2349;
}
.liketer_like_plashka_like_container_liked1 {
    background: rgb(216, 210, 210);
    color: #0B2349;
    border: 1px solid #0B2349;
}
.liketer_like_plashka_like_container_liked2 {
    background: rgb(245, 166, 20);
    color: #0B2349;
    border: 1px solid #0B2349;
}



.liketer_like_plashka_like_container:hover {
    /* background: rgb(39, 58, 68); */
}

.liketer_like_plashka_like_container_liked0:hover {
    background: rgb(181, 214, 223);
}
.liketer_like_plashka_like_container_liked1:hover {
    background: rgb(255, 255, 255);
}
.liketer_like_plashka_like_container_liked2:hover {
    background: gold;
}

.liketer_modal .ant-modal-body{
    background: linear-gradient(rgb(7, 29, 54), rgb(14, 60, 97)) !important;
    padding: 20px !important;
}