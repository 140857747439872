.person_fights_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    display: flex;
    flex-direction: row;

    justify-content: space-evenly;
    align-items: center;
    
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 12px;   

    row-gap: 15px;

    overflow-y: auto;
    cursor: pointer;

}