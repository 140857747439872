.operation_little {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgb(196, 196, 196);
    /* background: rgb(0, 28, 51); */
    /* background: linear-gradient(rgb(0, 29, 46), rgb(1, 43, 68)) */
    background: rgb(255, 255, 255);

    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    margin-left: 0px;

    /* image-rendering: pixelated; */
    /* image-resolution: 3200; */
    image-rendering: optimizeSpeed;
    column-gap: 5px;

}

.operation_little_title {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px;
    row-gap: 5px;
}

.operation_little_title_date {
    height: 10px;
    font-family: 'PFD';
    font-size: 11px;
    color: rgb(5, 56, 80);
}

.operation_little_title_text {
    font-family: 'PFD';
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
}

.operation_little_subject {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.operation_little_resourses {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* padding-left: 30px; */

    column-gap: 5px;
}

.operation_little_resourses img {
    height: 41px;
    width: auto;
}

.operation_little_one_resourse {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.operation_little_one_resourse_img {
    height: 25px;
}

.operation_little_one_resourse_img img {
    height: 100%;
    width: auto;
}

.operation_little_one_resourse_value {
    text-align: center;
    width: 100%;
    font-family: 'PFD';
    font-size: 15px;
    font-weight: bold;
    color: #006b09;
}

.operation_little_one_resourse_value_minus {
    color: #5c0000;
}