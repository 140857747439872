.ui_plashka {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(rgb(235, 235, 235), white); */

    /* background-position: center; */

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    /* border: 1px solid gray; */

    overflow-y: auto;

}

.main_center_container_mobile .ui_plashka{
    justify-content: space-around;
    align-items: center;

}