.card-night-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: none;

    color: white;
}

.card-night-cover img {
    width: 100%;
    height: 100%;
}

.card-night-content {

    width: 100%;
    height: 100%;
    
}

.card-night-content-logo-img {

    position: absolute;
    width: 94%;

    top: 30%;
    left: 3%;   


}

.card-night-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-night-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-night-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-night-content-title {

    position: absolute;
    width: 90%;
    text-align: center;

    top: 7%;   
    left: 5%;

    font-size: 1vw;

    font-family: 'Bewlay';
    text-transform: uppercase;

    font-weight: bold;

}

.card-night-content-tier {
    position: absolute;
    width: 80%;
    text-align: right;

    top: 88%;

    font-size: 120%;

    font-family: 'Bewlay';
    text-transform: uppercase;  

    font-weight: bold;

}