.inlarge_event_row {
    width: 100%;
    height: 50px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    border-top: 1px solid rgb(214, 214, 214);

}

.inlarge_event_row_logo {
    width: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.main_center_container_mobile .inlarge_event_row_logo {
    width: 90px;
    min-width: 60px;
}

.inlarge_event_row_title {
    font-family: 'PFD';
    font-size: 18px;
    color: rgb(5, 56, 80);
    max-width: 420px;
    width: 420px;
    min-width: 420px;
}

.main_center_container_mobile .inlarge_event_row_title {
    font-size: 15px;
    width: 210px;
    min-width: 210px;

    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.inlarge_event_row_title__down {
    display: flex;
    flex-direction: row;
    height: 16px;
}

.inlarge_event_row_dates {
    font-family: 'PFD';
    font-size: 16px;
    color: rgb(5, 56, 80);
    width: 100px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.main_center_container_mobile .inlarge_event_row_dates {
    font-size: 13px;
    width: unset;
}


.inlarge_event_row_status {
    font-family: 'PFD';
    color: rgb(5, 56, 80);
    width: 130px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.inlarge_event_row_region {
    font-family: 'PFD';
    color: rgb(5, 56, 80);
    width: 130px;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
}
