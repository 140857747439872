.card-trefa-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: 1px rgb(68, 2, 2) solid;
}

.card-trefa-cover img {
    width: 100%;
    height: 100%;
}

.card-trefa-content {

    width: 100%;
    height: 100%;
    
}

.card-trefa-content-logo-img {

    position: absolute;
    width: 90%;

    top: 18%;
    left: 10%;   


}

.card-trefa-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-trefa-content-mini-img {
    position: absolute;
    width: 20%;

    top: 83%;
    left: 10%;    
}

.card-trefa-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-trefa-content-title {

    position: absolute;
    width: 90%;
    text-align: left;

    top: 10%;   
    left: 10%;

    font-size: 1vw;

    font-family: 'MyriadPro';
    text-transform: uppercase;

    font-weight: bold;

    transform: skewY(-10deg);


}

.card-trefa-content-tier {
    position: absolute;
    width: 80%;
    text-align: right;

    top: 81%;

    font-size: 80%;

    font-family: 'MyriadPro';
    text-transform: uppercase;  

    font-weight: bold;

    transform: skewY(-4deg);

}