.card-flowers-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: none;

    color: white;
}

.card-flowers-cover img {
    width: 100%;
    height: 100%;
}

.card-flowers-content {

    width: 100%;
    height: 100%;
    
}

.card-flowers-content-logo-img {

    position: absolute;
    width: 90%;

    top: 7%;
    left: 5%;   


}

.card-flowers-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-flowers-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-flowers-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-flowers-content-title {

    position: absolute;
    width: 90%;
    text-align: center;

    top: 75%;   
    left: 5%;

    font-size: 1vw;

    font-family: 'MyriadPro';
    text-transform: uppercase;

    filter: drop-shadow(5px 5px rgb(87, 1, 1));

    font-weight: bold;

    color: white;

}

.card-flowers-content-tier {
    position: absolute;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    top: 6%;

    font-size: 120%;

    font-family: 'MyriadPro';
    text-transform: uppercase;  

    font-weight: bold;

    filter: drop-shadow(1px 1px rgb(87, 1, 1));

}