.calendar_page {
    background: white;
    width: 100%;
    min-height: 300px;

    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 70px;

}

.calendar_page
.data_changer_header {
    margin-bottom: 5px;
}

.calendar_page_seasons {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}


.main_center_container_mobile 
.calendar_page_seasons {
    justify-content: center;
    column-gap: 8px;
}

.calendar_page_seasons_season {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.calendar_page_seasons_season > span {
    font-family: 'PFD';
    font-size: 20px;
    color: gray;
    cursor: pointer;
}

.main_center_container_mobile 
.calendar_page_seasons_season > span {
    font-size: 13px;
}

.calendar_page_seasons_season_key_choosed {
    color: rgb(0, 57, 83) !important;
    cursor: default !important;
    font-weight: bold;
}

.calendar_page_upper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    min-height: 320px;
}

.main_center_container_mobile 
.calendar_page_upper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    min-height: 320px;
}


.calendar_page_upper
.data_changer_container {
    padding: 0px;
}

.calendar_page_upper_left {
    width: 330px;
    min-width: 330px;
    padding-left: 50px;
}

.main_center_container_mobile
.calendar_page_upper_left {
    padding-left: 20px;
}


.calendar_page_upper_right {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    margin-right: 48px;
}
.main_center_container_mobile
.calendar_page_upper_right {
    width: 80%;
    max-height: 300px;
    overflow-y: auto;
    /* margin-right: 48px; */
    margin: 0px;
    overflow-x: hidden;
}

.main_center_container_mobile
.calendar_page_upper_right 
.inlarge_event_row_title a {
    font-size: 11px;
}

.main_center_container_mobile
.calendar_page_upper_right 
.inlarge_event_row_title {
    overflow-x: visible;
    white-space: unset;
    line-height: 12px;
}

.main_center_container_mobile
.calendar_page_upper_right 
.event_big_plashka__status_text {
    line-height: 22px;

}



.calendar_page_months {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    flex-wrap: wrap;
    row-gap: 15px;

}