.onenew_little {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background: rgb(0, 28, 51); */
    /* background: linear-gradient(rgb(0, 29, 46), rgb(1, 43, 68)) */
    padding-left: 10px;
    padding-right: 10px;

}

.onenew_little__date {
    width: 100%;
    height: 18px;
    font-size: 11px;
    color: #EF7F1A;
    font-family: 'Uni';

    display: flex;
    flex-direction: row;
    column-gap: 5px;
    overflow: hidden;
}

.onenew_little__date > span {
    min-width: fit-content;
}

.onenew_little__title {
    width: 100%;
    max-height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    font-size: 18px;
    line-height: 15px;
    font-family: 'MyriadPro';
}

.onenew_little__devider {
    width: 90%;
    height: 1px;
    background: black;
    margin-top: auto;

}

.onenew_little__title_event_newnews {
    cursor: pointer;
    transition-duration: 1s;
}

.onenew_little__title_event_newnews:hover {
    color: rgb(1, 112, 163)
}

.onenew_little__title_hot {
    color: #cf680d;
    font-weight: bold;
}