.person_video_mini_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    background: none;
    display: flex;
    flex-direction: column;
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 12px;
}