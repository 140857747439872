.infoModal
.ant-modal-content {
    width: 850px !important;
    min-width: 850px !important;
}

.infoModalResults
.ant-modal-body {
    background: linear-gradient(rgb(255, 255, 255), rgb(236, 236, 236));
    padding: 0px;
    display: flex;
    justify-content: center;
}

.infoModalMobileStyle
.ant-modal-body {
    padding: 2px;
    width: 300px !important;
    min-width: 300px !important;
}

.modal_medal_content {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    column-gap: 15px;

}

.infoModalMobileStyle .modal_medal_content {

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    width: 100%;

    column-gap: 15px;

}

.modal_medal_left {    
    background: linear-gradient(rgb(0, 13, 29), rgb(5, 56, 80));
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    width: 300px;
    min-width: 300px;
    min-height: 300px;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
}

.modal_medal_left img {
    width: 100%;
    height: auto;
}

.modal_medal_right {
    width: 100%;
    padding: 10px;
}

.infoModalMobileStyle 
.modal_medal_left {   
    width: 100%;
    min-width: unset;
    min-height: unset;

}

.modal_item_left {    
    background: none;
    width: 300px;
    min-width: 300px;
    min-height: 300px;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
}

.modal_item_right {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 15px;
    font-family: 'PFD';
    color: rgb(0, 57, 83);
    row-gap: 4px;
}

.modal_item_right h3 {
    font-size: 30px;
    font-family: 'UNI';
    color: rgb(0, 57, 83) !important;
}

.modal_item_right h4 {
    font-size: 20px;
    font-family: 'UNI';
    color: rgb(0, 57, 83) !important;
    margin-top: 10px;
}

.modal_item_right_row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    column-gap: 20px;
}


.modal_newnews_content {
 width: 100%;
 height: fit-content;
 
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 align-items: center;

 padding: 15px;

}

.infoModalMobileStyle_news .modal_newnews_content {
    padding-left: 15px;
    padding-right: 15px;
}

.modal_newnews_content_title {
    font-family: 'PFD';
    color: white;
    background: rgb(0, 57, 83);

    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;

    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.infoModalMobileStyle_news .modal_newnews_content_title {

    font-size: 25px;

    padding-left: 10px;
    padding-right: 10px;
    padding-top: 13px;
    padding-bottom: 13px;
}

.modal_newnews_content_downed_type {
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;  
    width: 100%;
    margin-left: 10px;
}

.modal_newnews_content_downed {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    column-gap: 10px;
    margin-bottom: 20px;
    width: 100%;
    padding-bottom: 10px;

    border-bottom: 1px solid rgba(128, 128, 128, 0.199);
}

.infoModalMobileStyle_news .modal_newnews_content_downed {
    height: 35px;
}

.modal_newnews_content_downed > span {
    font-size: 15px;
    font-family: 'Uni';
    color: gray;
    margin-left: 10px;
}

.infoModalMobileStyle_news .modal_newnews_content_downed > span {
    font-size: 12px;
}

.modal_newnews_content_downed_type_right {
    margin-left: auto;
}

.modal_newnews_content_text {
    height: fit-content;
    width: 100%;
}

.modal_eventSchoolResults_content {  
    
    width: 100%;
    height: 100%;
    
    background: linear-gradient(rgb(255, 255, 255), rgb(209, 208, 208));
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    position: relative;

    z-index: 2;

    overflow: hidden;
}

.modal_eventSchoolResults_conten_back {

    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0),  rgb(209, 208, 208), rgb(209, 208, 208));
}

.modal_eventSchoolResults_conten_back2 {
    position: absolute;
    z-index: 1;
    width: 78%;
    height: 53%;
    opacity: 0.15;
    top: 17%;
    left: 10.5%;
    
    background-size: cover !important;
    background-position: center !important;
}

.infoModalMobileStyle
.modal_eventSchoolResults_conten_back2 {
    width: 98%;
    height: 53%;
    left: 1%;
}

.infoModalMobileStyle
.medal-div-with-count > span {
    font-family: 'PFD';
    font-size: 180% !important;
}


.modal_eventSchoolResults_conten_upper {

    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    position: absolute;
    z-index: 3;
}

.modal_eventSchoolResults_content_school {
    height: fit-content;
    margin-top: 20px;
    margin-bottom: 20px;
}

.modal_eventSchoolResults_content_event_img {
    width: 94%;
    margin-top: 10px;
    border-radius: 20px;
    background-size: cover !important;
    background-position: center !important;
}

.modal_eventSchoolResults_content_event_text {
    font-family: 'PFD';
    color: rgb(5, 56, 80);
    font-size: 22px;
    text-align: center;
}

.infoModalMobileStyle
.modal_eventSchoolResults_content_event_text {
    font-size: 14px;
}

.modal_eventSchoolResults_content_event_text > span {
    font-size: 30px;
}


.infoModalMobileStyle
.modal_eventSchoolResults_content_event_text > span {
    font-size: 14px;
}

.modal_eventSchoolResults_content_event_medals {
    background: linear-gradient(rgb(0, 13, 29), rgb(5, 56, 80));
    width: 100%;
    margin-top: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 3%;
}

.modal_eventSchoolResults_content_event_medals_count {
    
    
    font-size: 200%;
    font-family: 'PFD';

    width: 100%;
    color: rgb(5, 56, 80);

    margin-top: 2%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;  

    column-gap: 3%;

    
    margin-bottom: 2%;
}

.infoModalMobileStyle 
.modal_eventSchoolResults_content_event_medals_count {
    font-size: 130%;
}

.modal_eventSchoolResults_content_event_places {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;  
    column-gap: 4%;
    width: 100%
}

.modal_eventSchoolResults_content_event_places_text {
    font-size: 150%;
    font-family: 'PFD';

    width: fit-content;
    color: rgb(5, 56, 80);
}

.modal_eventSchoolResults_content_goldbg {
    border: 2px solid rgb(192, 173, 0);
    background: linear-gradient(rgb(248, 238, 103), rgb(173, 145, 16));
    color: rgb(5, 56, 80);
}

.modal_eventSchoolResults_content_silverbg {
    border: 2px solid rgb(131, 147, 150);
    background: linear-gradient(rgb(236, 236, 236), rgb(136, 136, 136));
    color: rgb(5, 56, 80);
}

.modal_eventSchoolResults_content_bronzebg {
    border: 2px solid rgb(161, 105, 0);
    background: linear-gradient(rgb(255, 175, 55), rgb(141, 97, 15));
    color: rgb(5, 56, 80);
}

.infoModalPDFRules {
    width: max(900px, 70vw) !important;
    height: 800px;
    min-height: 900px;
}

.infoModalPDFRules
.ant-modal-body {
    background: linear-gradient(rgb(255, 255, 255), rgb(236, 236, 236));
    padding: 0px;
    display: flex;
    justify-content: center;
}

.infoModalPDFRules 
.ant-modal-body {
    background: linear-gradient(rgb(255, 255, 255), rgb(236, 236, 236));
    padding: 0px;
    display: flex;
    justify-content: center;
}

.modal_pdfrules_content {
    width: 900px;
    height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
}

.infoModalMobileStyle {
    width: 300px;
    max-width: 300px;
}

.infoModalMobileStyle
.ant-modal-content {
    width: 300px !important;
    min-width: 300px !important;
}


.videoModalResults {
    width: fit-content !important;
}

.videoModalResults .ant-modal-body {
    padding: 8px;
    background: linear-gradient(rgb(255, 255, 255), rgb(156, 156, 156)) !important;
    border-radius: 20px;
    /* overflow: hidden; */
}

.videoModalResults .ant-modal-content {
    border-radius: 25px;
}

.infoModalNews {
    width: 1100px !important;
}

.infoModalMobileStyle_news {
    width: 100vw !important;
}

.infoModalMobileStyle_news .ant-modal-body {
    padding: 0px;
    background: linear-gradient(rgb(255, 255, 255), rgb(224, 224, 224)) !important;
    /* overflow: hidden; */
}

.ParticipatorsListContent_content {
    width: 100%;
}

.ParticipatorsListContent_content table {
    width: 100%;
}

.ParticipatorsListContent_content td {
    /* width: 50%; */
    /* max-width: 100px; */
    border: 1px solid gray;
}

.modal_list_header {
    font-weight: bold;
}