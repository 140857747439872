.school_new_news_plashka {
    width: 100%;
    height: 100%;
    background: rgb(253, 253, 253);
    
    display: flex;
    flex-direction: row;

    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    padding: 10px;

}

.school_new_news_plashka_nonews {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-family: 'PFD';
    color: rgb(158, 158, 158);
}

.school_new_news_plashka_nonews span {
    text-align: center;
}