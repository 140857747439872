.trefa_plashka__ {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;

    /* transform-style: preserve-3d !important;
    perspective: 120px !important;
    perspective-origin: center center; */

    transition-property: top;
    transition-duration: 1s;

}

.trefa_layout__ {
    position: relative;
}

.___no_transition
.trefa_plashka__ {
    transition-duration: 0s;
}