

.loginShowPerson {
    margin-left: auto;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;

}

.loginShowPerson_personFIO {
    color: white;
    font-family: 'MyriadPro';
    margin-right: 10px;
    cursor: pointer;
}

.loginShowPerson_personFIO a {
    color: white !important;
    margin-right: 10px;
}

.main_center_container_mobile .loginShowPerson_personFIO {
    color: rgb(0, 72, 105);
    font-family: 'MyriadPro';
}

.main_center_container_mobile .loginShowPerson_personFIO a {
    color: rgb(0, 72, 105) !important;
    margin-right: 10px;
}

.loginShowPerson_MiniMenu_back {
    position: fixed;
    top: 0px;
    left: 0px;
    background: rgba(0, 72, 105, 0.178);
    width: 100vw;
    height: 100vh;
    z-index: 999;
}

@keyframes minimenublah {
    from { opacity: 0; top: 30px}
    to {opacity: 1; top: 40px}
}

.loginShowPerson_MiniMenu {
    width: 300px;
    height: fit-content;

    position: absolute;
    background: rgb(255, 255, 255);
    /* background: linear-gradient(#d1d1d1, white); */
    border: 1px solid rgb(199, 199, 199);
    left: -180px;
    top: 40px;
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    border-radius: 5px;

    animation-name: 'minimenublah';
    animation-duration: 0.6s;
    animation-fill-mode: forwards;

    z-index: 1000;
}

.loginShowPerson_MiniMenu_item {
    width: 100%;
    height: fit-content;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding-left: 15px;
    padding-right: 15px;
    font-size: 17px;
    font-family: 'PFD';

}

.loginShowPerson_span {
    cursor: pointer;
}

.loginShowPerson_MiniMenu_item > span {
    color: black;
    cursor: pointer;
}

.loginShowPerson_MiniMenu_item > span:hover {
    color: rgb(0, 72, 105);
}

.loginNoUser {
    margin-left: auto;
    margin-right: 20px;
}

.loginEnter {
    font-family: 'MyriadPro';
    color: white;
    cursor: pointer;
}

.main_center_container_mobile .loginEnter {
    color: rgb(0, 72, 105);
}

.login-modal .ant-modal-body {
    background: linear-gradient(rgb(209, 209, 209), white)
}

.resourses-modal .ant-modal-body {
    padding: 5px !important;
}

.registration-modal .ant-modal-body {
    background: linear-gradient(rgb(245, 245, 245), white);
    /* width: 1200px; */
}

.registration-modal-mobile .ant-modal-body {
    background: linear-gradient(rgb(245, 245, 245), white);
    /* width: 95%; */
}

.forget_login_container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.forget_login_container_social {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 20px;
}