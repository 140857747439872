.person_raiting_history_plashka {
}

.person_raiting_history_plashka__oneraiting {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 35px;
    
    /* background: rgb(243, 243, 243);
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */

    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(207, 207, 207);

    transition-duration: 0.5s;
    font-family: 'PFD';
    font-weight: 100;
    text-transform: uppercase;

}

.person_raiting_history_plashka__oneraiting_row {
   animation: 0.7s one_row_anim forwards ease-in-out;
}

@keyframes one_row_anim {
    from {
        margin-left: 30px;
        opacity: 0;
    }
    to {
        margin-left: 0px;
        opacity: 1;
    }
}

.person_raiting_history_plashka__blank {
    width: 100%;
    height: 20px;
}

.person_raiting_history_plashka__oneraiting_row_selected,
.person_raiting_history_plashka__oneraiting_row:hover {
    background: white;
}



.person_raiting_history_plashka__oneraiting_header {
    height: 20px;
    font-family: 'Uni';
    border-bottom: none;
    /* border-bottom: 1px solid rgb(110, 110, 110); */
}

.main_center_container_mobile
.person_raiting_history_plashka__oneraiting_header {
    font-size: 10px !important;
}

.person_raiting_history_plashka__oneraiting_avatar {
    width: 40px;
    padding-left: 20px;
}

.main_center_container_mobile .person_raiting_history_plashka__oneraiting_avatar {
    width: 15px;
    padding-left: 4px;
}

.person_raiting_history_plashka__oneraiting_competition {
    width: 440px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 20px;
}

.main_center_container_mobile 
.person_raiting_history_plashka__oneraiting_competition {
    white-space: unset !important;

    font-size: 9px !important;
}

.person_raiting_history_plashka__oneraiting_season {
    color: rgb(5, 56, 80);
}

.person_raiting_history_plashka__oneraiting_category {
    width: 190px;
    line-height: 14px;
}

.main_center_container_mobile .person_raiting_history_plashka__oneraiting_category {
    width: 190px;
    line-height: 11px;
    font-size: 9px !important;
}

.person_raiting_history_plashka__oneraiting_scores {
    width: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.person_raiting_history_plashka__oneraiting_place {
    width: 90px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 50px;
    margin-right: 3px;
}

.person_raiting_history_plashka__oneraiting_place_inside {
    width: 45px;
}

.main_center_container_mobile 
.person_raiting_history_plashka__oneraiting_place_inside {
    width: 22px;
    font-size: 10px;
}

.person_raiting_history_plashka__oneraiting_header
.person_raiting_history_plashka__oneraiting_place {
    justify-content: flex-start;

    padding-left: 10px;
}