.person_page {
    height: fit-content;
    min-height: 600px;
    padding-bottom: 30px;
}

.aaa {
    width: max(1100px, 90vw) !important;
}

.person_page_inventar_modal {
    width: max(700px, 60vw) !important;
}

.aaa .ant-modal-header {
    background: rgb(5, 56, 80);
}

.aaa .ant-modal-close-x {
    color: white;
}

.aaa .ant-modal-title {
    color: white;
    font-family: 'PFD';
    text-transform: uppercase;
}

.person_page_inventar_modal_mobile .ant-modal-body {
    padding-left: 0px;
    padding-right: 0px;
}

.person_page_inventar_modal_mobile {
}