.person_data_change {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
    /* font-family: 'Uni'; */
    color: rgb(5, 56, 80);

    
    font-family: 'PFD';

}

.person_data_change_tabs .ant-tabs-tab {
    color: rgb(5, 56, 80);
    text-transform: uppercase;
}

.person_data_change_tabs .ant-tabs-tab-active {
    color: #1890ff;
}

.person_data_change_tabs .ant-tabs-left-content {
    color: black;
}

.person_data_change_recovery {
    text-transform: none;
    font-family: 'MyriadPro';
    font-size: 18px;
    color: black;
}