.person_nodata_plashka {
    width: 100%;
    height: 100%;
    background: rgb(253, 253, 253);
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    position: relative;
    overflow: hidden;
    column-gap: 15px;

    border-radius: 10px;
    border: 1px solid rgb(255, 187, 0);

}

.person_nodata_plashka > img {
    width: 40px;
    height: 40px;
}

.person_nodata_plashka > div {
    width: 60%;
    height: fit-content;
    font-family: 'PFD';
}

.person_nodata_plashka_attentions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}

.person_nodata_plashka_attentions > b {
    color: rgb(243, 97, 0);
}

.person_nodata_plashka_attentions > span {
    color:rgb(5, 56, 80);
    cursor: pointer;
}

.person_nodata_plashka_attentions > span:hover {
    color:rgb(5, 90, 129);
}