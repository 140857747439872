.event_little {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgb(196, 196, 196);
    /* background: rgb(0, 28, 51); */
    /* background: linear-gradient(rgb(0, 29, 46), rgb(1, 43, 68)) */
    background: linear-gradient(rgb(255, 255, 255), rgb(243, 251, 255))
}

.event_little_for_news {
    background: none;
    border-bottom: none;
    white-space: nowrap;

    overflow: hidden;
}

.event_little:last-child {
    border-bottom: none;
}

.event_little__avatar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.event_little__title {
    width: 100%;
    height: fit-content;
    font-family: 'MyriadPro';
    /* color: white; */
}

.event_bigvideo {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    column-gap: 10px;

    background: rgb(0, 28, 70);
}

.event_bigvideo__title {
    font-family: 'Uni';
    color: white;
}

.main_center_container_mobile .event_bigvideo__title {
    font-size: 10px;
}
