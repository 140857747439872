.card-clouds-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: none;

    color: white;
}

.card-clouds-cover img {
    width: 100%;
    height: 100%;
}

.card-clouds-content {

    width: 100%;
    height: 100%;
    
}

.card-clouds-content-logo-img {

    position: absolute;
    width: 90%;

    top: 1%;
    left: 5%;   


}

.card-clouds-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-clouds-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-clouds-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-clouds-content-title {

    position: absolute;
    width: 90%;
    text-align: center;

    top: 65%;   
    left: 5%;

    font-size: 1vw;

    font-family: 'Bewlay';
    text-transform: uppercase;

    font-weight: bold;

    color: rgb(250, 197, 117);
    filter:drop-shadow(0 0 6px rgb(8, 0, 53))

}

.card-clouds-content-tier {
    position: absolute;
    width: 80%;
    text-align: left;

    top: 85%;

    font-size: 110%;

    font-family: 'Bewlay';
    text-transform: uppercase;  

    font-weight: bold;

}