.big_group_plashka {
    width: 100%;
    height: 100%;
    background: rgb(253, 253, 253);
    
    display: flex;
    flex-direction: row;

    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    position: relative;
    overflow: hidden;

    border-top-left-radius: 80px;
    border-bottom-right-radius: 40px;

}

.main_center_container_mobile .big_group_plashka {
    
    border-top-left-radius: 40px;
    border-bottom-right-radius: 20px;
}


.big_group_plashka___avatar_back {
    position: absolute;
    opacity: 0;
    transform: scale(1.8);
    left: -30%;
    animation: 'school_avatar_back' 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

@keyframes school_avatar_back {
    from {
        left: -30%;
        opacity: 0;
    }
    1% {
        opacity: 0;
    }
    to {
        left: -3%;
        opacity: 0.2;
    }
}

.big_group_plashka___avatar_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;  
}

.big_group_plashka___info_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    position: relative; 
    padding-left: 50px;
    padding-top: 20px;
}

.main_center_container_mobile .big_group_plashka___info_container {
    padding-left: 15px;
    padding-top: 10px;
}

.big_group_plashka___info_container___name {
    text-align: left; 
    width: 100%;
    /* font-size: 47px; */
    font-family: 'MyriadPro';
}

.main_center_container_mobile .big_group_plashka___info_container___name {
    font-size: 25px !important;
    height: 30px;
}

.big_group_plashka___info_container___info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;  
    width: 100%;
    font-size: 22px;
    font-family: 'MyriadPro';
}

.big_group_plashka___info_container___school_group_cordao {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;  
    padding-left: 20px;
    width: 100%;
    column-gap: 10px;
}

.main_center_container_mobile .big_group_plashka___info_container___school_group_cordao {
    column-gap: 6px;
}

.big_group_plashka___info_container___school_group_cordao 
.rectAvatar {
    /* margin-right: 40px; */
}

.big_group_plashka_medals {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    column-gap: 5px;
}


.main_center_container_mobile 
.big_group_plashka__region {
    margin-left: auto;
    margin-right: 20px;
}

.big_group_plashka__region
.numberText_upper_text {
    font-size: 20px !important;
}

.main_center_container_mobile 
.big_group_plashka__region
.numberText_upper_text {
    font-size: 12px !important;
}