.persons_little_plashka {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(rgb(235, 235, 235), white); */

    /* background-position: center; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* border: 1px solid gray; */

    overflow-y: auto;
    overflow-x: hidden;

}

.persons_little_plashka_addmore {
    width: 100%;
    height: 40px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    background: rgb(0, 57, 83);
    color: white;
    font-family: 'PFD';
    font-size: 16px;

    margin-top: 10px;

}