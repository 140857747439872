.card-trefafire-cover {

    padding: 0px !important;
    border: 1px solid rgb(110, 59, 0);
    border-radius: 7%;
    display: flex;
    justify-content: center;
    color: white;
}

.card-trefafire-cover img {
    width: 100%;
    height: 100%;
}

.card-trefafire-content {

    width: 100%;
    height: 100%;
    
}

.card-trefafire-content-logo-img {

    position: absolute;
    width: 70%;

    top: 2%;
    left: 15%;   


}

.card-trefafire-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-trefafire-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-trefafire-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-trefafire-content-title {

    position: absolute;
    width: 90%;
    text-align: center;

    top: 60%;   
    left: 5%;

    font-size: 1vw;

    font-family: 'Bewlay';
    text-transform: uppercase;

    filter: drop-shadow(3px 3px black);

    font-weight: bold;
    color: rgb(255, 174, 0);
    opacity: 0.8;

}

.card-trefafire-content-tier {
    position: absolute;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    top: 87%;

    font-size: 120%;

    font-family: 'Bewlay';
    text-transform: uppercase;  

    font-weight: bold;
    color: rgb(255, 136, 0);

}