.card-points-cover {

    padding: 0px !important;
    border: none;
    border-radius: 2%;
    display: flex;
    justify-content: center;

    border: none;

    color: white;
}

.card-points-cover img {
    width: 100%;
    height: 100%;
}

.card-points-content {

    width: 100%;
    height: 100%;
    
}

.card-points-content-logo-img {

    position: absolute;
    width: 94%;

    top: 0%;
    left: 3%;   


}

.card-points-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-points-content-mini-img {
    position: absolute;
    width: 20%;

    top: 85%;
    left: 5%;    
}

.card-points-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-points-content-title {

    position: absolute;
    width: 90%;
    text-align: center;

    top: 65%;   
    left: 5%;

    font-size: 1vw;

    font-family: 'MyriadPro';
    text-transform: uppercase;

    font-weight: bold;

}

.card-points-content-tier {
    position: absolute;
    width: 80%;
    text-align: right;

    top: 88%;

    font-size: 120%;

    font-family: 'MyriadPro';
    text-transform: uppercase;  

    font-weight: bold;

}