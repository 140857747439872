.card-mega-cover {

    padding: 0px !important;
    border: none;
    border-radius: 10%;
    display: flex;
    justify-content: center;

}

.card-mega-cover-strike {
   background: linear-gradient(rgb(175, 0, 0), rgb(95, 2, 2));
}

.card-mega-cover-movement {
    background: linear-gradient(rgb(1, 134, 52), rgb(0, 94, 5));
 }

 .card-mega-cover-defence {
    background: linear-gradient(rgb(2, 66, 206), rgb(0, 39, 97));
 }

 .card-mega-cover-tier3 {
    background: linear-gradient(rgb(255, 166, 1), rgb(230, 207, 0));
 }

.card-mega-cover img {
    width: 96%;
    height: auto;
}

.card-mega-content {

    width: 100%;
    height: 100%;
    
}

.card-mega-content-logo-img {

    position: absolute;
    width: 80%;

    top: 10%;
    left: 10%;   


}

.card-mega-content-logo-img img {
    width: 100%;
    height: auto;  
}

.card-mega-content-mini-img {
    position: absolute;
    width: 20%;

    top: 83%;
    left: 10%;    
}

.card-mega-content-mini-img img {
    width: 100%;
    height: auto;    
}

.card-mega-content-title {

    position: absolute;
    width: 70%;
    text-align: left;

    top: 62%;   
    left: 10%;

    font-size: 1vw;

    font-family: 'CH';
    text-transform: uppercase;


}

.card-mega-content-tier {
    position: absolute;
    width: 80%;
    text-align: right;

    top: 3%;

    font-size: 70%;

    font-family: 'CH';
    text-transform: uppercase;  
}