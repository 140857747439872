.person_fkraccred_plashka {
    width: 100%;
    height: 100%;
    background: rgb(253, 253, 253);
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) );
    position: relative;
    overflow: hidden;
    column-gap: 15px;

    border-radius: 10px;
    border: 1px solid rgb(92, 92, 92);

}

.person_fkraccred_plashka_top {
    font-family: 'UNI';
    color: #053850;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px;
    text-align: right;
}

.person_fkraccred_plashka_top img {
    width: 25px;
    height: 25px;
}

.person_fkraccred_plashka_top_payment
.event_block_admission_downed {
    font-size: 10px;
}

.person_fkraccred_plashka_top > span {
    max-width: 180px;
    line-height: 20px;
}

.person_fkraccred_plashka_bottom {
    font-family: 'PFD';
    font-size: 14px;
    cursor: pointer;
}

.person_fkraccred_plashka_bottom:hover {
    color: #053850;
}

.person_fkraccred_plashka_modal {
    width: 800px;
}

.person_fkraccred_plashka_modal_container {
    width: 100%;
    padding: 20px;
    min-height: 300px;
}

.person_fkraccred_plashka_modal_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #053850;
    font-size: 18px;
    font-family: 'PFD';

    row-gap: 15px
}