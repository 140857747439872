.videoplate_main {
    width: fit-content;
    height: fit-content;
    overflow: hidden;
}

.videoplate_main_container {
    position: relative;
}

.videoplate_main_competition {

    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.videoplate_main_competitors {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 5%;
    
    margin-bottom: -50%;

    animation-name: vpCompetitors;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes vpCompetitors {
    from {
        margin-bottom: -50%;
    }
    to {
        margin-bottom: 0%;
    }
}