.seqmonitor_plashka {
    width: 100%;
    height: 100%;
    /* margin: 24px; */
    /* background: linear-gradient(rgb(235, 235, 235), white); */
    /* background: rgb(243, 243, 243); */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, 0.281) ); */
    user-select: none;
    /* border-radius: 5px; */
}

.seqmonitor_plashka_vertical {
    flex-direction: column-reverse;
}

.seqmonitor_plashka_sequences {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    overflow-y: auto;
    overflow-x: hidden;
}

.seqmonitor_plashka_monitor {
    background: rgb(0, 36, 59);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}