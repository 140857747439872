
.trefa_text_edit_main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    row-gap: 20px;
    padding: 5px;
}

.trefa_text_edit_main_container .trefa_text_container_textblock {
    text-transform: none;
}

.trefa_text_edit_main_container_fields {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    padding: 10px;
    width: 100%;
}

.trefa_text_edit_left_container {
    min-width: 500px;
    max-width: 550px;

    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.trefa_text_edit_input_component {
    display: flex;
    flex-direction: row;
    position: relative;
}
.trefa_text_edit_input_component_x {
    position: absolute;
    cursor: pointer;
    top: -10px;
    left: 100%;
    font-family: 'Uni';
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    border: 1px solid gray;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.trefa_text_edit_input_controls {
    width: 100px;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    background: rgb(241, 241, 241);
}

.trefa_text_edit_input_button {
    min-width: 30px;
    padding-left: 5px;
    padding-right: 5px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid rgb(199, 199, 199);
    border-radius: 3px;

    cursor: pointer;
}

.trefa_text_edit_image_component {
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 7px;
    position: relative;

}

.trefa_text_edit_image_component_row {
    width: 100%;  
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    column-gap: 5px;
}

.trefa_text_edit_added_component {
    width: 100%;
    height: 70px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 7px;
    border: 1px dotted gray;
    background: rgb(230, 230, 230);

    color: gray;

    margin-top: 5px;


}