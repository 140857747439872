.sport_changer_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    padding: 30px;
    padding-top: 10px;
    font-family: 'PFD';

    column-gap: 30px;

}

.sport_changer_header {
    width: 100%;
    height: fit-content;
    color: rgb(90, 90, 90);
    font-size: 18px;
    margin-bottom: 30px;
}

.sport_changer_devider {
    width: 100%;
    height: 1px;
}

.sport_changer_component {
    width: 250px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.sport_changer_component_title {
    font-size: 17px;
    color: rgb(5, 56, 80);
}

.sport_changer_component_comp {
    color: rgb(48, 48, 48);
}

.sport_changer_component_text {
    color: rgb(90, 90, 90);
    font-size: 12px;
}

.sport_changer_footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 50px;
}